import { initializeApp } from 'firebase/app'
import { browserSessionPersistence, getAuth, GoogleAuthProvider, FacebookAuthProvider, setPersistence } from 'firebase/auth'
import { getDatabase, serverTimestamp } from "firebase/database";
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    // EMAIL : hardita.kurniawan@gmail.com
    apiKey: 'AIzaSyCZjvZ7thKa3y5jkaefWBDd3T3x8YzTa00',
    authDomain: 'laravelfirebase-eclipse.firebaseapp.com',
    databaseURL: 'https://laravelfirebase-eclipse-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'laravelfirebase-eclipse',
    storageBucket: 'laravelfirebase-eclipse.appspot.com',
    messagingSenderId: '678604480444',
    appId: '1:678604480444:web:d9e85e6c9dca659455d5b9',
    measurementId: 'G-6CVTK5YJGD'

    // EMAIL : testmycode1997@gmail.com
    // apiKey: "AIzaSyBIAJQswgRqGNtLs2d208QqfrAJ4ul4q1g",
    // authDomain: "eclipse-cc250.firebaseapp.com",
    // databaseURL: "https://eclipse-cc250-default-rtdb.asia-southeast1.firebasedatabase.app",
    // projectId: "eclipse-cc250",
    // storageBucket: "eclipse-cc250.appspot.com",
    // messagingSenderId: "1038252482839",
    // appId: "1:1038252482839:web:0c37cb60e18738b6975752",
    // measurementId: "G-5NGVQPGNF1"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth()
const database = getDatabase()
const firestore = getFirestore()
const serverTime = serverTimestamp()
const storage = getStorage()
const google = new GoogleAuthProvider()
const facebook = new FacebookAuthProvider()

setPersistence(auth, browserSessionPersistence)

export {
    app,
    auth,
    database,
    firestore,
    serverTime,
    storage,
    google,
    facebook
}
