<template>
  <div id="app">
    <transition name="fade">
      <div v-if="showOrientationWarning">
        <orientation-warning
          :show-orientation-warning="showOrientationWarning"
          :toggle-orientation-warning="toggleOrientationWarning"
          :isIos="isIos"
        >
        </orientation-warning>
      </div>
      <div v-else>
        <orientation-warning
          :show-orientation-warning="isIos ? showOrientationWarning = true : showOrientationWarning = false"
          :toggle-orientation-warning="toggleOrientationWarning"
          :isIos="isIos"
        >
        </orientation-warning>
        <router-view />
      </div>
    </transition>

    <span id="audio">
			<!-- <audio id="theme" preload="auto">
				<source src="./assets/sound/theme.wav" type="audio/wav">
				<source src="./assets/sound/theme.mp3" type="audio/mpeg">
			</audio> -->
			<audio id="touch-button" preload="auto">
				<source src="./assets/sound/touch-button.wav" type="audio/wav">
				<source src="./assets/sound/touch-button.mp3" type="audio/mpeg">
			</audio>
			<audio id="point-1" preload="auto">
				<source src="./assets/sound/point-1.wav" type="audio/wav">
				<source src="./assets/sound/point-1.mp3" type="audio/mpeg">
			</audio>
      <audio id="point-2" preload="auto">
				<source src="./assets/sound/point-2.wav" type="audio/wav">
				<source src="./assets/sound/point-2.mp3" type="audio/mpeg">
			</audio>
      <audio id="point-3" preload="auto">
				<source src="./assets/sound/point-3.wav" type="audio/wav">
				<source src="./assets/sound/point-3.mp3" type="audio/mpeg">
			</audio>
      <audio id="point-4" preload="auto">
				<source src="./assets/sound/point-4.wav" type="audio/wav">
				<source src="./assets/sound/point-4.mp3" type="audio/mp3">
			</audio>
			<audio id="game-win-1" preload="auto">
				<source src="./assets/sound/game-win-1.wav" type="audio/wav">
				<source src="./assets/sound/game-win-1.mp3" type="audio/mp3">
			</audio>
      <audio id="game-win-2" preload="auto">
				<source src="./assets/sound/game-win-2.wav" type="audio/wav">
				<source src="./assets/sound/game-win-2.mp3" type="audio/mp3">
			</audio>
      <audio id="level-complete" preload="auto">
				<source src="./assets/sound/level-complete.wav" type="audio/wav">
				<source src="./assets/sound/level-complete.mp3" type="audio/mp3">
			</audio>
      <audio id="game-over" preload="auto">
				<source src="./assets/sound/game-over.wav" type="audio/wav">
				<source src="./assets/sound/game-over.mp3" type="audio/mp3">
			</audio>
      <audio id="touch-button" preload="auto">
				<source src="./assets/sound/touch-button.wav" type="audio/wav">
				<source src="./assets/sound/touch-button.mp3" type="audio/mp3">
			</audio>
      <audio id="leave-button" preload="auto">
				<source src="./assets/sound/leave-button.wav" type="audio/wav">
				<source src="./assets/sound/leave-button.mp3" type="audio/mp3">
			</audio>
		</span>

  </div>
</template>

<script>
import OrientationWarning from "@/components/OrientationWarning.vue"
import { mapActions, mapState } from "vuex";
import { Howl, Howler } from "howler";
import { logEvent, setCurrentScreen } from "firebase/analytics";
import { analytics } from './firebase/firebaseInit'

export default {
  metaInfo: {
    changed(metaInfo) {
      setCurrentScreen(analytics, {screenName: metaInfo.title})
      logEvent(analytics, 'screen_view', {
        firebase_screen: metaInfo.title, 
      });
      logEvent(analytics, {screenName: 'page_view'});
      logEvent(analytics, 'screen_view', {
        eventName: metaInfo.title,
      });
    }
  },
  components: {
    OrientationWarning,
  },
  props: {
    items: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      sfx: null,
      sound: null,
      isIos: false,
      showOrientationWarning: false,
      deviceOrientation: 'potrait',
    };
  },
  watch: {
    audio_playing(newCount, oldCount) {

      if (this.audio_object) {
        if (newCount == true) {
          console.log('watch audio playing TRUE')
            // this.$store.commit('PLAY_AUDIO', true)
            this.sound.play();
        } else {
          console.log('watch audio playing FALSE')
          // this.$store.commit('PLAY_AUDIO', false)
            this.sound.stop();
          // Howler.unload()
        }
      }
    },
  },
  computed: {
    ...mapState(["audio_playing", "audio_object"]),
  },
  created() {
    this.detectDevice()
    this.playAudio()

    if (this.audio_object == false) {
      this.sound = new Howl({
        src: [
          require("./assets/sound/theme.mp3"),
        ],
        volume: 0.5,
        loop: true,
      });
      this.$store.commit("SET_AUDIO_OBJECT", true);
    }

    window.addEventListener("blur", () => {
      console.log('BLUR')
      console.log('audio playing')
      console.log(this.audio_playing)
      if (this.audio_playing) {
        console.log('inside audio playing == true')
        this.$store.commit('PLAY_AUDIO', false)
        // this.sound.stop();
      }
    });

    window.addEventListener("focus", () => {
      console.log('FOCUS')
      console.log('audio playing')
      console.log(this.audio_playing)
      if (!this.audio_playing) {
        console.log('inside audio playing == false')
        this.$store.commit('PLAY_AUDIO', true)
        // this.sound.play();
      }
    });

    window.scrollTo(0, 1)
    this.showOrientationWarning = window.innerWidth > window.innerHeight
    document.documentElement.style.setProperty('--homewidth', `${window.innerWidth}px`)
    document.documentElement.style.setProperty('--homeheight', `${window.innerHeight}px`)
    const _this = this
    window.scrollTo(0, 1)
    window.addEventListener('resize', () => {
      window.scrollTo(0, 1)
      _this.showOrientationWarning = window.innerWidth > window.innerHeight
      document.documentElement.style.setProperty('--homewidth', `${window.innerWidth}px`)
      document.documentElement.style.setProperty('--homeheight', `${window.innerHeight}px`)
    })
  },
  methods: {
    detectDevice() {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
      if (isIOS && navigator.userAgent.includes("Instagram")) {
        this.isIos = true
      }
      if (!isIOS && navigator.userAgent.includes("Instagram")) {
        window.location.href = "intent:"+window.location.href+"#Intent;package=com.android.chrome;end";
      }

    },
    playAudio() {
      console.log('assign variable')
      this.sfx = {
        touch: new Howl({
          src: [
            require("./assets/sound/touch-button.wav"),
            require("./assets/sound/touch-button.mp3"),
          ]
        }),
        leave: new Howl({
          src: [
            require("./assets/sound/leave-button.wav"),
            require("./assets/sound/leave-button.mp3"),
          ]
        }),
        point1: new Howl({
          src: [
            require("./assets/sound/point-1.wav"),
            require("./assets/sound/point-1.mp3"),
          ]
        }),
        point2: new Howl({
          src: [
            require("./assets/sound/point-2.wav"),
            require("./assets/sound/point-2.mp3"),
          ]
        }),
        point3: new Howl({
          src: [
            require("./assets/sound/point-3.wav"),
            require("./assets/sound/point-3.mp3"),
          ]
        }),
        point4: new Howl({
          src: [
            require("./assets/sound/point-4.wav"),
            require("./assets/sound/point-4.mp3"),
          ]
        }),
        gameWin1: new Howl({
          src: [
            require("./assets/sound/game-win-1.wav"),
            require("./assets/sound/game-win-1.mp3"),
          ]
        }),
        gameWin2: new Howl({
          src: [
            require("./assets/sound/game-win-2.wav"),
            require("./assets/sound/game-win-2.mp3"),
          ]
        }),
        gameLevelComplete: new Howl({
          src: [
            require("./assets/sound/level-complete.wav"),
            require("./assets/sound/level-complete.mp3"),
          ]
        }),
        gameOver: new Howl({
          src: [
            require("./assets/sound/game-over.wav"),
            require("./assets/sound/game-over.mp3"),
          ]
        }),
      }
    },
    toggleOrientationWarning(width, height) {
      this.showOrientationWarning = width > height
    },
  }
};
</script>

<style lang="scss">
@import "./assets/styles/global.scss";
.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}
</style>
