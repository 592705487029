<template>
    <div>
        <div v-if="type == 'button'" @click="$parent.$parent.sfx.leave.play()">
            <router-link :to="url" class="bg-gradient-to-r from-green-500 to-green-700 py-4 block w-full rounded-xl">{{ name }}</router-link>
        </div>
        <div v-if="type == 'back'" @click="$parent.$parent.sfx.leave.play()">
            <router-link :to="url" class="pt-4 pl-5 gap-2 flex items-center">
                <img src="../assets/images/arrow-back.svg" alt="">
                <div class="text-xs font-bold text-white">
                    {{name}}
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Button',
  props: [
    'name',
    'type',
    'url'
  ],
}
</script>
