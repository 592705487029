<template>
	<div class="bg-green-1 h-screen w-full relative">
		<div class="absolute right-0 left-0 top-1/10 w-full text-center max-w-sm mx-auto">
			<h2 class="text-6xl text-white font-black mb-8">LEVEL 1</h2>
			<div class="relative">
				<div class="rounded-full bg-white w-40 h-40 mx-auto p-2 mb-5 shadow-2xl">
					<div class="flex flex-col justify-center">
						<img class="w-4 h-4 mx-auto" src="../assets/images/star-green.svg" alt="">
						<span class="text-lg font-bold text-green-4">Flick</span>
						<h2 class="text-7xl text-green-5 font-black">50</h2>
						<span class="text-lg font-bold text-green-4">Gums</span>
					</div>
				</div>
				<div class="rounded-full bg-green-5 w-28 h-28 ml-14 p-2 shadow-2xl">
					<div class="flex flex-col justify-center">
						<img class="w-5 h-5 mx-auto" src="../assets/images/icon-bell.svg" alt="">
						<span class="text-xs font-bold text-white-3">COLLECT</span>
						<h3 class="text-4xl font-bold text-white">60</h3>
						<span class="text-white-3" style="font-size:9px;">points</span>
					</div>
				</div>
				<div class="absolute -bottom-1/4 right-0 rounded-full bg-black w-28 h-28 mr-14 p-2 shadow-2xl">
					<div class="flex flex-col justify-center">
						<img class="w-5 h-5 mx-auto" src="../assets/images/icon-hour.svg" alt="">
						<span class="text-xs font-bold text-white-3">IN</span>
						<h3 class="text-4xl font-bold text-white">50</h3>
						<span class="text-white-3" style="font-size:9px;">seconds</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
}
</script>
