<template>
	<div id="wrapper">
		<div v-if="showOrientationWarning">

			<img 
				src="../assets/images/screen-profile.jpg" 
				alt="" 
				class="background"
				v-bind:id="[showOrientationWarning ? 'show' : 'hide']">
			
			<div class="information">
				
				<div class="information__title">{{ `${isIos ? 'Copy this link and open it in your Safari browser or Google Chrome for better experience' : 'You are now entering Desktop/Landscape Mode'}` }}</div> 
				<div v-if="!isIos">Please use your phone and rotate to potrait mode for better experience</div>
        <div v-if="isIos">
          <div class="relative w-full">
            <input class="bg-white bg-text-xs shadow-2xl text-black py-3 pl-3 pr-11 rounded-xl w-full" type="text" :value="url" id="copyLink" disabled>
            <div class="absolute bottom-3 right-3 bg-white" @click="copyPaste()">
              <img src="../assets/images/copy-paste.svg" alt="">
            </div>
          </div>
          <div id="snackbar">Copied!</div>
        </div>
			</div>
			
		</div>
	</div>
</template>

<script>
export default {
  data() {
    return {
      url: window.location.href,
      isSafari: false,
      isDetected: false,
      innerHeight: '',
      innerWidth: '',
      width: '',
      height: '',
      deviceOrientation: 'potrait',
      device: this.$store.getters['app/getDevice']
    }
  },
  props: {
    isIos: {
      type: Boolean
    },
    showOrientationWarning: {
			type: Boolean,
			default() {
				return false
			}
		},
    toggleOrientationWarning: { type: Function }
  },
  methods: {
    checkIsSafari() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/windows phone/i.test(userAgent)) {
        this.isSafari = false
        return false;
      }
      if (/android/i.test(userAgent)) {
        this.isSafari = false
        return false;
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.isSafari = true
        return true;
      }
      return false
    },
    copyPaste () {
			const copyText = document.getElementById('copyLink')
			const x = document.getElementById('snackbar')
			copyText.select()
			copyText.setSelectionRange(0, 99999)
			navigator.clipboard.writeText(copyText.value)
			x.className = 'show'
  			setTimeout(function () { x.className = x.className.replace('show', '') }, 3000)
		},
  },
  mounted() {
    let _this = this
    this.toggleOrientationWarning(window.innerWidth, window.innerHeight)
    
    window.scrollTo(0, 1)
    if(window.innerWidth > window.innerHeight) {
      document.documentElement.style.setProperty('--warningwidth', `${window.innerWidth}px`)
      document.documentElement.style.setProperty('--warningheight', `${window.innerHeight}px`)
    } else {
      document.documentElement.style.setProperty('--warningwidth', `${window.innerWidth}px`)
      document.documentElement.style.setProperty('--warningheight', `${window.innerHeight}px`)
    }

    window.addEventListener('resize', doOnOrientationChange)
    function doOnOrientationChange() {
      window.scrollTo(0, 1)
      _this.toggleOrientationWarning(window.innerWidth, window.innerHeight)
    }
  }
}
</script>

<style lang="scss" scoped>
	#wrapper {
    position: relative;
}

.background {
    top: 0;
    left: 0;
    position: relative;
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
.information {
    color: #fff;
    text-align: center;
    max-width: 350px;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 35%;

    &__title {
        font-weight: bold;
        margin-bottom: 8px;
    }
    
}
</style>