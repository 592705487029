<template>
  <div class="games">
    <div class="pt-4 pl-5 flex">
      <router-link :to="{ name: 'Homepage' }" replace>
        <img src="../assets/images/arrow-back.svg" alt="" />
      </router-link>
    </div>

    <div
      class="absolute left-0 right-0 top-0 bg-white rounded-b-full pb-6 mx-24 flex flex-col justify-center items-center shadow-4xl"
    >
      <p class="text-green-1 text-sm mt-2">Gums Flicked</p>
      <span class="font-bold text-green-1 text-6xl">{{ remainingCandy }}</span>
    </div>
    <div class="slash-3"></div>
    <div 
      class="absolute top-3 right-2 text-center flex flex-col gap-2"
    >
      <span class="text-sm text-white font-bold">LEVEL {{ level }}</span>
      <div
        class="bg-black-2 rounded-full flex flex-col justify-center items-center w-20 h-20 shadow-4xl"
      >
        <p class="text-white" style="font-size: 9px">Time Left</p>
        <div class="flex gap-1" v-if="gameTimer < 6">
          <img src="../assets/images/icon-time-red.svg" alt="" />
          <p class="text-sm text-red-1" style="font-size: 8px">
            <b class="text-red-1" style="font-size: 11px">{{ gameTimer }}</b> sec
          </p>
        </div>
        <div class="flex gap-1" v-else>
          <img src="../assets/images/icon-time.svg" alt="" />
          <p class="text-sm text-white" style="font-size: 8px">
            <b style="font-size: 11px">{{ gameTimer }}</b> sec
          </p>
        </div>
      </div>
    </div>
    <div class="absolute left-0 right-0 top-2/2 my-0 h-80">
      <vue-swing
        @throwout="onThrowout"
        @throwoutend="onThrowoutEnd"
        @dragstart="onDragStart"
        :config="config"
        ref="vueswing"
      >
        <div
          v-for="item in candy"
          :key="item.index"
          :id="`candy_${item.index}`"
          class="card absolute bottom-0 left-0 right-0 my-0 mx-auto"
        >
          <span><img :src="require('../assets/images/candy'+item.randomCandy+'.png')"/></span>
        </div>
      </vue-swing>

      <img
        class="mx-auto"
        style=""
        src="../assets/images/botol-mint-flick.png"
        alt=""
      />
      <div v-if="remainingCandy <= 49">
        <img src="../assets/images/hand-swipe.png" class="hidden" alt="" v-if="level == 1">
      </div>
      <div v-else>
        <img src="../assets/images/hand-swipe.png" class="swipe_effect absolute left-0 right-0 top-2/4 mx-auto block" alt="" v-if="level == 1">
      </div>
    </div>

    <div
      class="absolute bottom-12 left-0 right-0 mx-16 my-0 rounded-xl bg-white items-center justify-between p-2.5 flex gap-2 shadow-4xl"
    >
      <div class="flex gap-2">
        <div class="life-full" v-for="index in life" :key="`life-full${index}`">
          <img src="../assets/images/life-full.svg" alt="" />
        </div>
        <div class="life-empty" v-for="index in 3 - life" :key="`life-empty${index}`">
          <img src="../assets/images/life-empty.svg" alt="" />
        </div>
      </div>
      <p class="font-bold text-xs text-red-1">{{ life }} lives remaining</p>
    </div>

    <!-- Jika setiap level berhasil -->
    <ModalLevelComplete
      v-if="showModalLevelComplete"
      @continueGames="continueGames"
      :level="level"
      :pointLevel1="point_level_1"
      :pointLevel2="point_level_2"
      :pointLevel3="point_level_3"
    />

    <!-- Muncul sebelum game dimulai (ada jeda 0 detik) yang berisi informasi level yang akan dimainkan -->
    <ModalPreparation
      v-if="is_preparation_modal_visible"
      :level="level"
      :preparationTimer="preparationTimer"
    />

    <!-- Muncul jika level 1,2,3 selesai dimain kan -->
    <ModalSummary
      v-if="showModalSummary"
      @leaveGameTo="leaveGameTo"
      :pointLevel1="point_level_1"
      :pointLevel2="point_level_2"
      :pointLevel3="point_level_3"
    />

    <!-- Muncul jika user keluar dari game, sebelum game selesai -->
    <ModalLeave
      v-if="showModalLeave"
      @close="cancleDialog()"
      :modalMessage="modalMessage"
      @yesDialog="yesDialog()"
    />

    <!-- Muncul untuk member yg room masternya keluar dari game -->
    <ModalExit
			v-show="showModalLeaveForMember"
		>
			<template v-slot:body>
				<div class="bg-green-8 h-screen w-full pt-3 pb-12 relative">
					<div class="absolute left-0 right-0 top-1/10 w-full mx-auto text-center">
						<img class="mx-auto" src="../assets/images/icon-emote3.svg" alt="">
						<div class="mt-5 w-52 mx-auto">
							<h2 class="text-4xl text-white font-bold">OH NO! YOUR HOST</h2>
							<h2 class="text-4xl text-white font-bold">HAS LEFT THE ROOM</h2>
						</div>
						
						<div class="mt-11 mx-20 flex flex-col gap-5">
							<button @click.stop="play('multiPlayer')" class="rounded-2xl bg-green-5 py-5 px-8 text-base text-white">Create a new room</button>
							<button @click.stop="play('solo')" class="rounded-2xl bg-white-4 py-5 px-8 text-base text-green-9">Play solo</button>
							<div class="relative mt-20" @click="leaveRoom">
								<img class="absolute top-0.5 left-8" src="../assets/images/arrow-back.svg" alt="">
								<p class="text-sm text-white">to home</p>
							</div>
						</div>
					</div>
				</div>
			</template>
		</ModalExit>

    <audio id="audio-1" src="../assets/sound/point-1.mp3"></audio>
    <audio id="audio-2" src="../assets/sound/point-2.mp3"></audio>
    <audio id="audio-3" src="../assets/sound/point-3.mp3"></audio>
    <audio id="audio-4" src="../assets/sound/point-4.mp3"></audio>

  </div>
</template>

<script>
import ModalExit from "@/components/ModalExit.vue"
import ModalLevelComplete from "@/components/ModalLevelComplete.vue"
import ModalPreparation from "@/components/ModalPreparation.vue"
import ModalSummary from "@/components/ModalSummary.vue"
import ModalLeave from "@/components/ModalLeave.vue"
import VueSwing from "vue-swing"
import { auth, database } from "../firebase/firebaseInit"
import { ref, off, onValue, update} from "firebase/database"
import { mapActions, mapState } from "vuex"
import firebaseRepository from '../firebase/firebaseRepository'
import helper from "../helper"
import moment from "moment"

let listeners;

export default {
  name: "Games",
  components: {
    ModalExit,
    ModalLevelComplete,
    ModalPreparation,
    ModalSummary,
    ModalLeave,
    VueSwing,
  },
  watch: {
    preparationTimer: {
      async handler(value) {
        console.log('p', value)
        if (value !== null && value <= 0) {
          this.$store.commit('SET_PREPARATION_MODAL_VISIBILITY', false)
          this.$store.commit('SET_PREPARATION_TIMER', null)
          await this.$store.dispatch('imReady', false)
          if (this.is_room_master) {
            // stop preparation timer
            firebaseRepository.updateTimer('preparation', { roomID: this.roomID, status: 'stop', seconds: 0 })
            // start game timer
            const updates = {}
            updates[`rooms/${this.roomID}/is_game_timer_start`] = true
            update(ref(database), updates)
          }
        }
      }
    },

    gameTimer: {
      async handler(value) {
        console.log('gt', value)
        if (value !== null && value <= 0 && this.remainingCandy !== 0) {
          this.$store.commit('START_GAME_TIMER', false)
          this.$store.commit('SET_GAME_TIMER', null)
          const updates = {}
          updates[`rooms/${this.roomID}/is_timeout`] = true
          const updateRoom = update(ref(database), updates)
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      showModalLeave: false,
      showModalLevelComplete: false,
      showModalSummary: false,
      showModalLeaveForMember: false,

      config: {
        allowedDirections: [VueSwing.Direction.UP],
        isThrowOut: function (xOffset, yOffset, element, throwOutConfidence) {
          return throwOutConfidence > 0.5;
        },
        minThrowOutDistance: 500,
        maxThrowOutDistance: 500,
      },

      candy: [],

      modalMessage: "LEAVING ALREADY?",
      reason: 'just exit',

      gamePoint: 0,
      remainingCandy: 50,
      seconds: 0,

      index: null,
      level: null,
      point_level_1: null,
      point_level_2: null,
      point_level_3: null,
      to: null,
    };
  },
  computed: {
    ...mapState([
      'is_game_timer_enabled',
      'is_preparation_modal_visible', 
      'is_room_master', 
      'gameTimer',
      'life',
      'myPoint',
      'need_confirmation', 
      'preparationTimer', 
      'roomID' 
    ]),
  },
  methods: {
    ...mapActions([
      'createRoom',
      'getUserProfile',
      'userPoint',
      'leaveGameTo',
      'leaveRoom',
      'onThrowout',
      'openPreparationModal',
      'playSolo',
      'resetTimer',
      'roomMasterIsLeaving',
      'startGameTimer',
      'unsubscribe',
    ]),
    cancleDialog() {
      this.$store.commit('PLAY_AUDIO', true)
      this.showModalLeave = false
      this.to = null
    },
    async continueGames() {
      if (!this.is_room_master) return
      const updates = {}
      updates[`rooms/${this.roomID}/is_preparing_to_start`] = true
      updates[`rooms/${this.roomID}/is_next_level`] = true
      update(ref(database), updates)
      await this.getCountdownTime()
      this.$store.dispatch('createPointHistoryStatus', { status: false, index: this.index })
      this.showModalLevelComplete = false
    },
    gameStartAt() {
      let seconds
      switch (this.level) {
        case 1:
          seconds = 50
          break
        case 2:
          seconds = 30
          break
        case 3:
          seconds = 10
          break
      }
      return seconds
    },
    async getCountdownTime() {
      const game = await firebaseRepository.getDataRealtimeDB(`rooms/${this.roomID}`)
      this.level = game.level

      if (!game.is_finished) {
        if (!game.preparation_timer_start_at && !game.game_timer_start_at) {
          console.log('1')
          this.$store.dispatch('openPreparationModal')
          await this.$store.dispatch('prepareToStart')

        } else if (game.preparation_timer_start_at && !game.game_timer_start_at) {
          console.log('2')
          this.$store.dispatch('openPreparationModal')
          await this.$store.dispatch('getPreparationTime')
          this.$store.dispatch('startCountdownPreparationTimer')

        } else {
          console.log('3')
          if (this.is_room_master) {
            await this.$store.dispatch('getGameTimer')
            this.$store.dispatch('startCountdownGameTimer')
          }

        }
      }
    },
    async leaveGameTo(routeName) {
      this.$store.dispatch('leaveGameTo', { routeName: routeName, level: this.level, reason: this.reason})
    },
    mainGame() {
      listeners = ref(database, `rooms/${this.roomID}`)
			onValue(listeners, async (snapshot) => {
				const room = await firebaseRepository.getDataRealtimeDB(`rooms/${this.roomID}`)
				// room master left the room
				if (!room && !this.is_room_master) {
          this.resetTimer()
          this.stopListener()
          this.$store.commit('SET_CONDITION_NEED_CONFIRMATION', false)
          this.$store.commit('PLAY_AUDIO', false)
          this.showModalLeaveForMember = true
					return
				}

        // handle jika user login dengan id yg sama di beda device dan di device 1 user exit room maka id yg berada di device 2 akan masuk ke kondisi ini
				if (room.participant_data.find(data => data.id === auth.currentUser.uid) === undefined) {
					this.$store.commit('SET_CONDITION_NEED_CONFIRMATION', false)
					this.$router.replace({ name: 'Homepage' })
				}

				const response = snapshot.val()
        this.remainingCandy = 50 - response.total_candy
        this.index = helper.findUserIndex(response.participant_data, auth.currentUser.uid)

        //--- section preparation timer --- //
				if (response.is_preparing_to_start) {
          if (!this.is_room_master) this.level = response.level
          this.openPreparationModal()
          await this.$store.dispatch('imReady', true)
					const totalMemberReady = response.participant_data.filter(data => data.is_ready === true)

          // keadaan dimana semua member sudah ready
					if (totalMemberReady.length === response.participant_data.length) {
						if (this.is_room_master && !response.preparation_timer_start_at) {
							firebaseRepository.updateTimer('preparation', { roomID: this.roomID, status: 'start', seconds: 5 })
              setTimeout(async () => {
                await this.$store.dispatch('getPreparationTime')
                this.$store.dispatch('startCountdownPreparationTimer')
              }, 500)
						}

						if (!this.is_room_master) {
							if (response.preparation_timer_start_at) {
                setTimeout(async () => {
                  await this.$store.dispatch('getPreparationTime')
                  this.$store.dispatch('startCountdownPreparationTimer')
                }, 500)
							}
						}
					} 
        }

        if (!response.is_preparing_to_start) { 
					this.$store.commit('START_PREPARATION_TIMER', false)
			    this.$store.commit('SET_PREPARATION_TIMER', null)
          this.$store.commit('SET_PREPARATION_MODAL_VISIBILITY', false)
				}

        //--- section game timer --- //
        if (response.is_game_timer_start) {
          await this.$store.dispatch('imReady', true)
          const totalMemberReady = response.participant_data.filter(data => data.is_ready === true)

          // keadaan dimana semua member sudah ready
          if (totalMemberReady.length === response.participant_data.length) {
            // this.playSound('theme')
            this.$store.commit('PLAY_AUDIO', true)
            if (this.is_room_master  && !response.game_timer_start_at) {
              firebaseRepository.updateTimer('game', {roomID: this.roomID, status: 'start', seconds: this.gameStartAt() })
              setTimeout(async () => {
                await this.$store.dispatch('getGameTimer')
                this.$store.dispatch('startCountdownGameTimer')
              }, 100)
            }

            if (!this.is_room_master) {
              if (response.game_timer_start_at && !this.is_game_timer_enabled) {
                await this.$store.dispatch('getGameTimer')
                this.$store.dispatch('startCountdownGameTimer')
              }
            }
          }
        }

        // reset point_level when page has been reloaded
        if (response.level > 1) this.resetGamePoint([response.point_level_1, response.point_level_2, response.point_level_3])
        
        this.setGamePoint()
        
        if (response.is_finished) this.resetTimer()

        if (response.total_candy === 50) {
          // document.getElementById("theme").pause()
          this.$store.commit('PLAY_AUDIO', false)


          if (!response.is_finished) {
            this.seconds = this.gameStartAt() - this.gameTimer
            await this.updateRoomStatus(false)
            this.resetTimer()
            // create point_histories di setiap level
            await this.$store.dispatch('createPointHistory', { 
              gamePoint: this.gamePoint, 
              hasCreatedPointHistory: response.participant_data[this.index].has_created_point_history, 
              index: this.index,
              level: this.level,
              seconds: moment().valueOf() - response.game_timer_start_at
              // seconds: this.seconds
            })
            await this.$store.dispatch('imReady', false)
            await this.updateLeaderboard()
          }

          if (this.level === 3) {
            this.$parent.sfx.gameWin1.play()
            this.$parent.sfx.gameWin2.play()
            this.reason = 'game finished'
            this.showModalSummary = true

            // change room master status become false
            // await firebaseRepository.updateDocument(`users/${auth.currentUser.uid}`, { is_room_master: false })

            // if (this.is_room_master) setTimeout(this.$store.dispatch('cloningRooms', this.roomID), 500)

          } else {
            this.$parent.sfx.gameWin1.play()
            this.$parent.sfx.gameWin2.play()
            this.showModalLevelComplete = true
          }
        }

				if (response.is_timeout) {
          // document.getElementById("theme").pause()
          this.$store.commit('PLAY_AUDIO', false)
          this.$parent.sfx.gameOver.play()
          this.reason = 'time out'
          this.showModalSummary = true
          this.resetGamePoint([response.point_level_1, response.point_level_2, response.point_level_3])
          this.updateRoomStatus()

          // change room master status become false
          // await firebaseRepository.updateDocument(`users/${auth.currentUser.uid}`, { is_room_master: false })

          // if (this.is_room_master) setTimeout(this.$store.dispatch('cloningRooms', this.roomID), 500)

        }

        if (!this.is_room_master && response.is_next_level == true) {
          this.showModalLevelComplete = false
          const updates = {}
          updates[`rooms/${this.roomID}/is_next_level`] = false
          update(ref(database), updates)
          this.$store.dispatch('createPointHistoryStatus', { status: false, index: this.index })
          await this.getCountdownTime()
        }
			})
    },
    async play(type) {
      this.stopListener()
      if (this.life <= 0) {
        alert('Sorry, you can’t create a room because you don’t have any lives remaining. Try again tomorrow after your coffee break')
        this.$router.push({ name: 'Homepage' })
        return 
      }

      const roomId = helper.generateUUID()

      switch (type) {
        case 'multiPlayer':
          await this.$store.dispatch('createRoom', roomId)
          this.$router.replace({ name: 'Room', params: { id: roomId } })
          break;
      
        default:
          await this.$store.dispatch('playSolo', roomId)
          setTimeout(() => {
            this.$router.replace({ name: 'games_play', params: { id: roomId } })
            this.$router.go()
          }, 0)
          break;
      }
    },
    setGamePoint() {
      switch (this.level) {
        case 1:
          this.gamePoint = 10
          this.point_level_1 = 10
          break

        case 2:
          this.gamePoint = 20
          this.point_level_2 = 20
          break

        case 3:
          this.gamePoint = 30
          this.point_level_3 = 30
          break
      }
    },
    resetGamePoint(arrayPoint) {
      this.point_level_1 = arrayPoint[0]
      this.point_level_2 = arrayPoint[1]
      this.point_level_3 = arrayPoint[2]
    },
    showModal(message = null) {
      this.modalMessage = message;
      this.showModalLeave = true;
      return true;
    },
    stopListener() {
      off(listeners)
    },
    async updateLeaderboard() {
      let dataLeaderboardWeekly = await helper.calculatePoint('weeks'),
          dataLeaderboardMonthly = await helper.calculatePoint('months')

      // update rangking table
      this.updateLeaderboardTable('weekly', dataLeaderboardWeekly)
      this.updateLeaderboardTable('monthly', dataLeaderboardMonthly)

      // update ranking user
      this.updateRankingUser('weekly', dataLeaderboardWeekly)
      this.updateRankingUser('monthly', dataLeaderboardMonthly)
    },
    updateLeaderboardTable(type, payload) {
      firebaseRepository.updateDocument(`leaderboards/${type}`, { array_of_ranking: payload })
    },
    updateRankingUser(type, payload) {
      let lastRanking
      payload.forEach((data) => {
        type == "weekly" ? lastRanking = { last_ranking_weekly: data.ranking } : lastRanking = { last_ranking_monthly: data.ranking }

        firebaseRepository.updateDocument(`users/${data.id}`, lastRanking)
      })
    },
    updateRoomStatus(isTimeout = true) {
      const updates = {}

      updates[`rooms/${this.roomID}/is_finished`] = isTimeout || this.level === 3 ? true : false
      updates[`rooms/${this.roomID}/game_timer_start_at`] = null
      updates[`rooms/${this.roomID}/is_game_timer_start`] = false
      updates[`rooms/${this.roomID}/is_timeout`] = isTimeout ? true : false

      if (this.is_room_master) {
        if (!isTimeout && this.level !== 3) updates[`rooms/${this.roomID}/total_candy`] = 0
        if (this.level < 3) updates[`rooms/${this.roomID}/level`] = isTimeout ? this.level : this.level + 1
        updates[`rooms/${this.roomID}/total_point`] = this.point_level_1 + this.point_level_2 + this.point_level_3
      }

      if (this.level <= 3) {
        updates[`rooms/${this.roomID}/point_level_${this.level}`] = isTimeout ? 0 : this.gamePoint
        updates[`rooms/${this.roomID}/point_level_${this.level}_finish_at`] = isTimeout ? 0 : this.gameTimer
        updates[`rooms/${this.roomID}/point_level_${this.level}_start_at`] = this.gameStartAt()
      }

      if (this.is_room_master) update(ref(database), updates)
    },
    async yesDialog() {
      this.$store.commit('PLAY_AUDIO', true)
      this.resetTimer()
      this.stopListener()
      await this.$store.dispatch('leaveGameTo', { routeName: this.to, level: this.level, reason: this.reason})
    },

    // per-candy-an
    reloadCandy() {
      let i;
      for (i = 0; i < 500; i++) {
        const randomCandy = Math.ceil(Math.random() * 5)
        this.candy.push({ index: i, randomCandy: randomCandy })
      }
    },
    onThrowoutEnd(event) {
      var myobj = document.getElementById(event.target.id)
      // let audioName = 'point-' + Math.ceil(Math.random() * 4)
      // this.playSound(audioName)
      // document.getElementById("audio-" + Math.ceil(Math.random() * 4)).play()
      let randomNumber = Math.ceil(Math.random() * 4);

      if (randomNumber == 1) {
        this.$parent.sfx.point1.play();
      } else if (randomNumber == 2) { 
        this.$parent.sfx.point2.play();
      } else if (randomNumber == 3) {
        this.$parent.sfx.point3.play();
      } else if (randomNumber == 4) {
        this.$parent.sfx.point4.play();
      }

      

      myobj.remove()
    },
    onDragStart(event) {
      document.getElementById(event.target.id).childNodes[0].style.opacity = 1
    },
    // end per-candy-an
  },
  async created() {
    this.$store.commit('SET_CONDITION_NEED_CONFIRMATION', true)
    this.$store.commit("SET_ROOM_ID", this.$route.params.id)
    this.reloadCandy()
    await this.getUserProfile(auth.currentUser.uid)
  },
  mounted: function() {
    this.$store.commit('PLAY_AUDIO', false)
		this.$nextTick(async () => {
      await this.getCountdownTime()
			await this.mainGame()
		})
	},
  async beforeRouteLeave(to, from, next) {
    if (this.need_confirmation) {
      if (this.to) {
        console.log('to')
        this.$store.commit('PLAY_AUDIO', true)
        next()
      } else {
        console.log('false')
        this.$store.commit('PLAY_AUDIO', false)
        this.to = to
        this.showModal(this.modalMessage)
      }
    } else {
      this.stopListener()
      next()
    }
  },
};
</script>

<style scoped>
.card {
  opacity: 1;
  align-items: center;
  background-color: transparent;
  border-radius: 20px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  display: flex;
  font-size: 35px;
  height: 15rem;
  justify-content: center;
  width: 154px;
  z-index: 2;

  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.card.change {
  opacity: 0;
  transition: all 1s;
}
.card span {
  opacity: 0;
  height: 50px;
  width: 50px;
  position: absolute;
  top: -20px;
}

.swipe_effect {
  -webkit-animation: action 1s infinite  alternate;
    animation: action 1s infinite  alternate;
}
@-webkit-keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}

@keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
</style>
