import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import { store } from './store/store'
import { auth } from './firebase/firebaseInit'
import VueSocialSharing from 'vue-social-sharing'

import browserDetect from "vue-browser-detect-plugin";

Vue.config.productionTip = false

let app

auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})

Vue.use(browserDetect);
Vue.use(VueSocialSharing);
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

