<template>
  <div class="leaderboard">
    <router-link to="/homepage" class="pt-5 pl-5 gap-2 flex items-center">
      <img src="../assets/images/arrow-back.svg" alt="" @click="$parent.sfx.leave.play()" />
    </router-link>
    <h2
      class="text-2xl font-bold text-white absolute left-0 right-0 top-3 w-2/4 mx-auto text-center"
    >
      Leaderboard
    </h2>
    <div class="px-20 mt-5 relative">
      <div class="loading text-white" v-if="loading">Loading...</div>
      <!-- Best Of 3 Weekly -->
      <div v-if="weeklyBestOfThree">
        <div class="owl-carousel owl-theme" v-if="displayScore == 'weekly'">
          <div
            class="item relative"
            v-for="item in weeklyBestOfThree"
            :key="item.id"
          >
            <img
              class="rounded-2xl h-48 object-cover"
              :src="
                item.photoProfile != null
                  ? item.photoProfile
                  : require('../assets/images/slide-example.png')
              "
              alt=""
            />
            <div
              class="absolute left-0 right-0 rounded-2xl bottom-0 bg-purple-1 py-2 px-3.5 flex justify-between items-center"
            >
              <div>
                <p class="text-white-3 text-xs">{{ item.name }}</p>
                <span class="text-white text-lg font-bold"
                  >{{ item.additional_point }} Points</span
                >
              </div>
              <span class="text-3xl text-white font-bold"
                >#{{ item.ranking }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-else class="absolute left-0 right-0 mx-auto top-1/4">
        <h1 class="text-center text-white">No Data</h1>
      </div> -->

      <!-- Best Of 3 Monthly -->
      <div v-if="leaderboardMonthlyScore.best_of_three">
        <div class="owl-carousel owl-theme" v-if="displayScore == 'monthly'">
          <div
            class="item relative"
            v-for="item in leaderboardMonthlyScore.best_of_three"
            :key="item.id"
          >
            <img
              class="rounded-2xl h-48 object-cover"
              :src="
                item.photoProfile != null
                  ? item.photoProfile
                  : require('../assets/images/slide-example.png')
              "
              alt=""
            />
            <div
              class="absolute left-0 right-0 rounded-2xl bottom-0 bg-purple-1 py-2 px-3.5 flex justify-between items-center"
            >
              <div>
                <p class="text-white-3 text-xs">{{ item.name }}</p>
                <span class="text-white text-lg font-bold"
                  >{{ item.additional_point }} Points</span
                >
              </div>
              <span class="text-3xl text-white font-bold"
                >#{{ item.ranking }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else class="left-0 right-0 mx-auto top-1/4">
        <h1 v-if="!loading" class="text-center text-white">No Data</h1>
      </div>
    </div>
    <div class="mt-5 bg-white rounded-t-xxl pt-5 px-7 pb-3">
      <!-- Switch State Button -->
      <div class="flex justify-between">
        <div
          id="weekly_button"
          :class="{ selected: displayScore == 'weekly' }"
          class="inline-block p-2.5 px-12 bg-white rounded-xl border border-green-1 text-sm text-black-3 font-bold"
          @click="switchState(displayScore, 'weekly')"
        >
          Weekly
        </div>
        <div
          id="monthly_button"
          :class="{ selected: displayScore == 'monthly' }"
          class="inline-block p-2.5 px-12 bg-white rounded-xl border border-green-1 text-sm text-black-3 font-bold"
          @click="switchState(displayScore, 'monthly')"
        >
          Monthly
        </div>
      </div>

      <!-- Individual Point & Ranking -->
      <div
        class="bg-linear-3 py-2.5 px-3 rounded-lg mt-3 flex justify-between items-center"
      >
        <div class="flex items-center gap-2">
          <img :src="photoPreview" class="w-9 h-9 rounded-full" alt="" style="object-fit: cover;" />
          <div>
            <div class="text-green-7 text-sm font-medium">{{ name }}</div>
            <span class="text-lg text-green-6 font-bold"
              >{{
                displayScore == "weekly"
                  ? leaderboardWeeklyScore.my_point
                  : leaderboardMonthlyScore.my_point
              }}
              points</span
            >
          </div>
        </div>
        <div class="flex items-center gap-2.5">
          <span class="text-2xl font-bold text-green-6"
            >#{{
              displayScore == "weekly"
                ? leaderboardWeeklyScore.my_ranking
                : leaderboardMonthlyScore.my_ranking
            }}</span
          >
          <img
            v-if="displayScore == 'weekly' && lastRankingWeekly != null"
            :src="
              lastRankingWeekly - leaderboardWeeklyScore.my_ranking >= 0
                ? require('../assets/images/arrow-green-up.svg')
                : require('../assets/images/arrow-red-down.svg')
            "
            alt=""
          />
          <img
            v-if="displayScore == 'monthly' && lastRankingMonthly != null"
            :src="
              lastRankingMonthly - leaderboardMonthlyScore.my_ranking >= 0
                ? require('../assets/images/arrow-green-up.svg')
                : require('../assets/images/arrow-red-down.svg')
            "
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- Ranking 4-20 Weekly -->
    <div
      class="bg-linear-1 bg-white pt-3 px-7"
      style="overflow-y: scroll; height: 100%"
      v-if="displayScore == 'weekly'"
    >
      <div v-if="leaderboardWeeklyScore.best_of_twenty">
        <div
          class="item-leaderboard bg-linear-2 py-2.5 px-3 rounded-lg mb-2 flex justify-between items-center"
          v-for="item in leaderboardWeeklyScore.best_of_twenty"
          :key="item.id"
        >
          <div class="flex items-center gap-2">
            <img
              :src="
                item.photoProfile != null
                  ? item.photoProfile
                  : require('../assets/images/icon-user.png')
              "
              class="w-9 h-9 rounded-full"
              alt=""
              style="object-fit: cover;"
            />
            <div>
              <div class="text-gray-10 text-sm font-medium">
                {{ item.name }}
              </div>
              <span class="text-lg text-gray-9 font-bold"
                >{{ item.additional_point }} points</span
              >
            </div>
          </div>
          <div class="flex items-center gap-2.5">
            <span class="text-2xl font-bold text-gray-10"
              >#{{ item.ranking }}</span
            >
            <img
              v-if="item.last_ranking_weekly != null"
              :src="
                item.last_ranking_weekly - item.ranking >= 0
                  ? require('../assets/images/arrow-green-up.svg')
                  : require('../assets/images/arrow-red-down.svg')
              "
              alt=""
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div
          class="bg-linear-2 py-2.5 px-3 rounded-lg mb-2 flex justify-between items-center"
        >
          <div class="flex items-center gap-2">
            <img
              src="../assets/images/icon-user.png"
              class="w-9 h-9 rounded-full"
              alt=""
              style="object-fit: cover;"
            />
            <div>
              <div class="text-gray-10 text-sm font-medium">NO DATA</div>
              <span class="text-lg text-gray-9 font-bold">0 points</span>
            </div>
          </div>
          <div class="flex items-center gap-2.5">
            <span class="text-2xl font-bold text-gray-10">-</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Ranking 4-20 Monthly -->
    <div
      class="bg-linear-1 bg-white pt-3 px-7"
      style="overflow-y: scroll; height: 100%"
      v-if="displayScore == 'monthly'"
    >
      <div v-if="leaderboardMonthlyScore.best_of_twenty">
        <div
          class="item-leaderboard bg-linear-2 py-2.5 px-3 rounded-lg mb-2 flex justify-between items-center"
          v-for="item in leaderboardMonthlyScore.best_of_twenty"
          :key="item.id"
        >
          <div class="flex items-center gap-2">
            <img
              :src="
                item.photoProfile != null
                  ? item.photoProfile
                  : require('../assets/images/icon-user.png')
              "
              class="w-9 h-9 rounded-full"
              alt=""
              style="object-fit: cover;"
            />
            <div>
              <div class="text-gray-10 text-sm font-medium">
                {{ item.name }}
              </div>
              <span class="text-lg text-gray-9 font-bold"
                >{{ item.additional_point }} points</span
              >
            </div>
          </div>
          <div class="flex items-center gap-2.5">
            <span class="text-2xl font-bold text-gray-10"
              >#{{ item.ranking }}</span
            >
            <img
              v-if="item.last_ranking_monthly != null"
              :src="
                item.last_ranking_monthly - item.ranking >= 0
                  ? require('../assets/images/arrow-green-up.svg')
                  : require('../assets/images/arrow-red-down.svg')
              "
              alt=""
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div
          class="bg-linear-2 py-2.5 px-3 rounded-lg mb-2 flex justify-between items-center"
        >
          <div class="flex items-center gap-2">
            <img
              src="../assets/images/icon-user.png"
              class="w-9 h-9 rounded-full"
              alt=""
              style="object-fit: cover;"
            />
            <div>
              <div class="text-gray-10 text-sm font-medium">NO DATA</div>
              <span class="text-lg text-gray-9 font-bold">0 points</span>
            </div>
          </div>
          <div class="flex items-center gap-2.5">
            <span class="text-2xl font-bold text-gray-10">-</span>
          </div>
        </div>
      </div>
    </div>
    <bottom-sheet>
      <div class="pb-12 px-7 pt-1">
        <div class="text-center">
          <h4 class="text-white font-bold text-sm">THIS WEEK’S PRIZES</h4>
          <p class="text-sm text-white">Available until 17 March 2022</p>
          <div class="relative mt-6 w-52 mx-auto">
            <img
              class="mx-auto"
              src="../assets/images/lazada-voucher.png"
              alt=""
            />
            <div class="absolute -bottom-5 -left-3">
              <img src="../assets/images/40-item.png" alt="" />
            </div>
          </div>
          <span class="text-xl text-white font-bold">Lazada Vouchers</span>
          <img class="mx-auto mt-2" src="../assets/images/airpod-max.png" alt="" style="width: 40%;">
          <p class="text-white text-md">
            5x Grand Prizes Winners with the highest scores. Winners list will be announced on
            8th April 2022
          </p>
          <!-- <div class="relative">
            <img
              class="w-52 h-32 object-contain mx-auto"
              src="../assets/images/grab-voucher.png"
              alt=""
            />
            <div class="absolute bottom-1" style="left: 4.5rem">
              <img src="../assets/images/1000-item.png" alt="" />
            </div>
          </div>
          <span class="text-xl text-white font-bold">Grab Vouchers</span> -->
        </div>
        <div class="mt-6 border-2 border-yellow-1 rounded-xl p-4 text-center">
          <p class="text-lg text-yellow-1">
            Play everyday and accumulate your points to win the rewards!
          </p>
        </div>
      </div>
    </bottom-sheet>
    <!-- <div trigger="0" id="sheet" class="fixed bg-linear-4 h-3/4 w-full rounded-t-xxl pt-5 pb-12 px-7" style="bottom: -70%;">
			<div id="borderClick" class="border-2 border-white rounded w-7 mx-auto"></div>
			<div class="mt-7 text-center">
				<h4 class="text-white font-bold text-xl">THIS WEEK’S PRIZES</h4>
				<p class="text-sm text-white">Available until 25 February 2022</p>
				<div class="relative mt-6">
					<img class="w-52 mx-auto" src="../assets/images/voucher-grab.png" alt="">
					<div class="absolute bottom-1 left-6">
						<img src="../assets/images/daun.png" alt="">
						<span class="absolute bottom-6 left-4 text-green-3 font-bold text-3xl" style="-webkit-text-stroke: 1px white;">20</span>
					</div>
				</div>
				<span class="text-xl text-white font-bold">Grab Vouchers</span>
			</div>
			<div class="mt-6 border-2 border-yellow-1 rounded-xl p-4 text-center">
				<p class="text-lg text-yellow-1">Play everyday and accumulate your points to win the rewards! </p>
			</div>
		</div> -->
    <audio-component></audio-component>
  </div>
</template>

<script>
// import { auth } from '../firebase/firebaseInit'
import { mapState, mapActions } from "vuex";
import BottomSheet from "@/components/BottomSheet.vue";
import AudioComponent from "@/components/AudioComponent.vue";
import { Howl } from 'howler';
export default {
  components: {
    BottomSheet,
    AudioComponent,
  },
  computed: {
    ...mapState([
      "lastRankingMonthly",
      "lastRankingWeekly",
      "leaderboardWeeklyScore",
      "leaderboardMonthlyScore",
      "name",
    ]),
    photoPreview() {
      return this.$store.state.photoPreview === null
        ? require("../assets/images/icon-user.png")
        : this.$store.state.photoPreview;
    },
  },
  data() {
    return {
      displayScore: "weekly",
      weeklyBestOfThree: "",

      loading: false
    };
  },

  mounted() {
    this.sliderLeaderboard();
  },

  methods: {
    ...mapActions(["leaderbaord", "tabButton"]),
    
    open() {
      this.$refs.myBottomSheet.open();
    },
    sliderLeaderboard() {
      $(".owl-carousel").owlCarousel({
        // loop: true,
        nav: true,
        margin: 10,
        dots: false,
        responsive: {
          0: {
            items: 1,
          },
        },
      });
    },
    switchState(buttonActive, data) {
      this.$parent.sfx.touch.play()
      if (data !== this.displayScore) {
        $(".owl-carousel").owlCarousel("destroy");
      }

      this.displayScore = data;
      if (buttonActive !== data) this.loading = true
      setTimeout(() => {
        this.loading = false
        this.sliderLeaderboard();
      }, 1000);
    },
    // bottomSheet () {
    // 	$('#borderClick').click(function () {
    // 		if ($('#sheet').attr('trigger') === '0') {
    // 			$('#sheet').animate({ bottom: '0' }, 700)
    // 			$('#sheet').attr('trigger', '1')
    // 		} else {
    // 			$('#sheet').animate({ bottom: '-70%' }, 700)
    // 			$('#sheet').attr('trigger', '0')
    // 		}
    // 	})
    // }
  },
  async created() {
    await this.$store.dispatch("leaderboard");
    this.weeklyBestOfThree = this.leaderboardWeeklyScore.best_of_three;
    // this.$store.dispatch('getUserProfile', auth.currentUser.uid)
    $(".owl-carousel").owlCarousel("destroy");
    setTimeout(() => {
      this.sliderLeaderboard();
    }, 0);
  },
};
</script>

<style scoped>
.loading {
  min-height: 140px; 
  display: flex;
  justify-content: center; 
  align-items: center;
}
</style>
