<template>
	<div class="room md:h-screen h-full">
		<div class="pt-4 pl-5 gap-2 flex items-center" @click="$router.replace({ name: 'Homepage' })" style="width: max-content">
			<img src="../assets/images/arrow-back.svg" alt="">
			<div class="text-xs font-bold text-white">
				Leave Room
			</div>
		</div>
		<div class="mx-14 mt-9">
			<div class="bg-white rounded-2xl py-4 px-5 shadow-3xl">
				<div class="flex justify-between items-center">
					<p class="text-xs font-bold">Hi, {{ name }}</p>
					<div class="flex gap-1">
						<div class="life-full" v-for="index in life" :key="`life-full${index}`">
							<img src="../assets/images/life-full.svg" alt="">
						</div>
						<div class="life-empty" v-for="index in 3 - life" :key="`life-empty${index}`">
							<img src="../assets/images/life-empty.svg" alt="">
						</div>
					</div>
				</div>
				<p class="text-base font-bold text-red-1">You have {{ life }} lives remaining</p>
			</div>
		</div>
		<div class="pt-8 px-5 pb-52">
			<p class="font-bold text-sm text-white mb-3">Party Member  {{ totalMember }}/20</p>
			<div class="grid grid-cols-4 md:grid-cols-9 gap-4">
				<div class="flex flex-col items-center relative text-center" v-for="item in participant" :key="item.id">
					<img v-if="item.is_room_master" class="absolute -top-2 -right-4" src="../assets/images/star-room.svg" alt="">
					<div class="bg-gray-7 rounded-2xl w-16 h-16">
						<img class="rounded-2xl object-cover" :src="item.photo_profile ? item.photo_profile : ( item.is_room_master ? require('../assets/images/no_photo_profile_rm.png') : require('../assets/images/no_photo_profile_member.png') )" alt="" style="height: 100%; width: 100%;">
					</div>
					<p v-if="item.id == id" class="text-xs mt-2 text-white font-bold">YOU</p>
					<p v-else class="text-xs mt-2 text-white font-bold">{{ item.name }}</p>
				</div>
				<div class="flex flex-col items-center" v-for="index in 20 - totalMember" :key="index">
					<div class="rounded-2xl w-16 h-16" style="background-color: rgba(255, 255, 255, 0.56);">
						<img class="rounded-2xl w-full h-full object-cover" src="../assets/images/question-mark.svg" alt="">
					</div>
					<p class="text-xs mt-2 text-white">Waiting...</p>
				</div>
			</div>
		</div>
		<div class="fixed bottom-0 bg-white rounded-t-3xl px-5 pb-5 pt-8 w-full text-center">
			<div class="flex flex-col gap-2.5">
				<button :disabled="totalMember < 2" type="submit" @click="prepareToStart" :class="`bg-${totalMember < 2 ? 'gray-7' : 'green-2'} py-4 block w-full rounded-xl font-bold text-white`">{{ is_room_master ? 'START GAME' : "I'M READY TO FLICK" }}</button>
				<div v-if="is_room_master" class="flex flex-col gap-2.5">
					<p class="text-xs text-gray-2">Or Invite Friends</p>
					<div class="flex gap-3 justify-between items-center">
						<div class="relative w-full">
							<input class="bg-linear-8 bg-text-xs shadow-2xl text-black py-3 pl-3 pr-11 rounded-xl w-full" type="text" :value="url" id="copyLink" disabled>
							<div class="absolute bottom-3 right-3 bg-white" @click="copyPaste()">
								<img src="../assets/images/copy-paste.svg" alt="">
							</div>
						</div>
						<div class="flex gap-3">
							<ShareNetwork
								class="bg-gradient-to-r from-green-400 to-green-600 py-3 px-3 rounded-xl w-12 h-12"
								network="whatsapp"
								title="Hey hey hey. Let’s take coffee break to the  next level. Click this link and join the team to earn more points. Airpods Max awaits!"
								url="url"
								description=""
								quote=""
								hashtags="game, flick"
							>
								<img class="w-full" src="../assets/images/whatsapp-icon.svg" alt="">
							</ShareNetwork>
							<ShareNetwork
								class="bg-gradient-to-r from-green-400 to-green-600 py-3 px-3 rounded-xl w-12 h-12"
								network="sms"
								title="Hey hey hey. Let’s take coffee break to the  next level. Click this link and join the team to earn more points. Airpods Max awaits!"
								url="url"
								description=""
								quote=""
								hashtags="game, flick"
							>
								<img class="w-full" src="../assets/images/sms-icon.svg" alt="">
							</ShareNetwork>
						</div>
					</div>
				</div>
			</div>
        </div>
		<div id="snackbar">Copied!</div>
		<ModalExit
			v-show="isModalVisible"
			@close="closeModal"
		>
			<template v-slot:body>
				<div class="bg-green-8 h-screen w-full pt-3 pb-12 relative">
					<div class="absolute left-0 right-0 top-1/10 w-full mx-auto text-center">
						<img class="mx-auto" src="../assets/images/icon-emote3.svg" alt="">
						<div :class="`mt-5 ${!is_room_master ? 'w-52 mx-auto' : ''}`">
							<h2 class="text-4xl text-white font-bold">{{ modalExitMessage1 }}</h2>
							<h2 class="text-4xl text-white font-bold">{{ modalExitMessage2 }}</h2>
						</div>
						<div class="mt-11 mx-20 flex flex-col gap-5" v-if="showModalLeaveWhenRoomMasterLeft">
							<button @click.stop="play('multiPlayer')" class="rounded-2xl bg-green-5 py-5 px-8 text-base text-white">Create a new room</button>
							<button @click.stop="play('solo')" class="rounded-2xl bg-white-4 py-5 px-8 text-base text-green-9">Play solo</button>
							<div class="relative mt-20" @click="leaveRoom">
								<img class="absolute top-0.5 left-8" src="../assets/images/arrow-back.svg" alt="">
								<p class="text-sm text-white">to home</p>
							</div>
						</div>
						<div class="mt-11 mx-24 flex flex-col gap-5" v-else>
							<div class="rounded-2xl bg-green-5 py-5 px-8 text-base text-white" @click="closeModal">Cancel</div>
							<button @click="confirm" class="rounded-2xl bg-white-4 py-5 px-8 text-base text-green-9">Yes</button>
						</div>
					</div>
				</div>
			</template>
		</ModalExit>

		<ModalExit
			v-show="is_preparation_modal_visible"
			@close="closeModal"
		>
			<template v-slot:body>
				<div class="bg-green-8 h-screen w-full pt-3 pb-12 relative">
					<div class="absolute left-0 right-0 top-1/4">
					<div class="bg-green-5 w-44 h-44 rounded-full mx-auto flex flex-col justify-center items-center relative">
						<div v-if="is_room_master" class="absolute right-3 top-3" @click="closePreparationModal"><img src="../assets/images/close-red.svg" alt=""></div>
						<p class="text-xs text-white">Ready to flick in</p>
						<span :class="`text-white font-black text-${typeof(preparationTimer) === 'number' ? 7 : 3}xl`">{{ preparationTimer }}</span>
					</div>
					</div>
				</div>
			</template>
		</ModalExit>

	<audio-component></audio-component>

	</div>
</template>

<script>
import helper from '../helper'
import firebaseRepository from '../firebase/firebaseRepository'
import { auth, database } from '../firebase/firebaseInit'
import { ref, off, onValue } from "firebase/database"
import { mapActions, mapState } from 'vuex'
import ModalExit from '@/components/ModalExit.vue'
import AudioComponent from '@/components/AudioComponent.vue'

let listeners

export default {
	name: 'Room',
	components: {
		ModalExit,
		AudioComponent,
	},
	watch: {
		preparationTimer: {
			handler (value) {
				if (value !== null && value <= 0) {
					console.log('nilai', value)
					this.$store.commit('SET_CONDITION_NEED_CONFIRMATION', false)
					
					helper.makeSyncronize(this.closePreparationModal())
					.then(() => helper.makeSyncronize(this.goToMainGame()))
				}
			},
			immediate: true
		}
	},
	computed: {
		...mapState([
			'id',
			'is_preparation_modal_visible',
			'is_room_master',
			'is_timer_enabled',
			'life',
			'modalExitMessage1',
			'modalExitMessage2',
			'name',
			'need_confirmation',
			'preparationTimer',
			'photoPreview',
			'roomID'
		])
	},
	data () {
		return {
			participant: [],

			url: window.location.href,

			to: null,
			totalMember: null,

			isModalVisible: false,
			showModalLeaveWhenRoomMasterLeft: false
		}
	},
	methods: {
		...mapActions([
			'createRoom',
			'getUserProfile',
			'leaveRoom',
			'openPreparationModal',
			'playSolo',
			'prepareToStart',
			'resetTimer',
			'roomMasterIsLeaving',
			'startGame',
			'unsubscribe',
		]),
		playSound(sound) {
			var audio = document.getElementById(sound);
			(audio !== null) ? audio.play(): console.log(sound + " not found");
		},
		closeModal () {
			this.$parent.sfx.touch.play()
			this.isModalVisible = false
			this.to = null
		},
		closePreparationModal () {
			this.$store.commit('START_PREPARATION_TIMER', false)
			this.$store.commit('SET_PREPARATION_TIMER', null)
			this.$store.commit('SET_PREPARATION_MODAL_VISIBILITY', false)
			if (this.is_room_master) { 
				firebaseRepository.updateTimer('preparation', { roomID: this.roomID, status: 'stop', seconds: 0 })
			}
		},
		async confirm () {
			this.$parent.sfx.leave.play()
			this.stopListener()
			this.is_room_master ? await this.roomMasterIsLeaving() : await this.unsubscribe()
			this.isModalVisible = false
			this.$router.replace(this.to)
		},
		copyPaste () {
			const copyText = document.getElementById('copyLink')
			const x = document.getElementById('snackbar')
			copyText.select()
			copyText.setSelectionRange(0, 99999)
			navigator.clipboard.writeText(copyText.value)
			x.className = 'show'
  			setTimeout(function () { x.className = x.className.replace('show', '') }, 3000)
		},
		async goToMainGame () {
			this.$store.commit('SET_PREPARATION_TIMER', 'Waiting..')
			this.$store.commit('SET_PREPARATION_MODAL_VISIBILITY', true)

			await this.$store.dispatch('imReady', false)
			await this.startGame()
			if (!this.is_room_master) {
				let roomListener
				const room = ref(database, `rooms/${this.roomID}`)
				roomListener = onValue(room, async (snapshot) => {
					if (snapshot.val().has_started) {
						this.$store.commit('SET_PREPARATION_MODAL_VISIBILITY', false)
						this.$router.replace({ name: 'games_play', params: { id: this.roomID } })
						off(roomListener)
						// this.$route.go()
					}
				})
			}
		},
		listParticipant () {
			listeners = ref(database, `rooms/${this.roomID}`)
			onValue(listeners, async (snapshot) => {
				const room = await firebaseRepository.getDataRealtimeDB(`rooms/${this.roomID}`)
				// room master left the room
				if (!room && !this.is_room_master) {
					this.resetTimer()
					this.showModal()
					this.showModalRoomMasterLeft()
					return
				}

				// handle jika user login dengan id yg sama di beda device dan di device 1 user exit room maka id yg berada di device 2 akan masuk ke kondisi ini
				if (room.participant_data.find(data => data.id === this.id) === undefined) {
					this.$store.commit('SET_CONDITION_NEED_CONFIRMATION', false)
					this.$router.replace({ name: 'Homepage' })
				}

				const response = snapshot.val()
				this.participant = response.participant_data
				this.totalMember = this.participant.length

				if (response.is_preparing_to_start) {
					this.openPreparationModal()
					this.$store.dispatch('imReady', true)
					const totalMemberReady = response.participant_data.filter(data => data.is_ready === true)

					// keadaan dimana semua member sudah ready
					if (totalMemberReady.length === response.participant_data.length) {
						if (this.is_room_master && !response.preparation_timer_start_at) {
							firebaseRepository.updateTimer('preparation', { roomID: this.roomID, status: 'start', seconds: 5 })
							setTimeout(async () => {
								await this.$store.dispatch('getPreparationTime')
								this.$store.dispatch('startCountdownPreparationTimer')
							}, 0)
						}

						if (!this.is_room_master) {
							if (response.preparation_timer_start_at) {
								await this.$store.dispatch('getPreparationTime')
								this.$store.dispatch('startCountdownPreparationTimer')
							}
						}
					} 
				}

				if (!response.is_preparing_to_start) { 
					this.$store.dispatch('imReady', false)
					this.closePreparationModal()
				}
			})
		},
		async play(type) {
			if (this.life <= 0) {
				alert('Sorry, you can’t create a room because you don’t have any lives remaining. Try again tomorrow after your coffee break')
        		this.$router.push({ name: 'Homepage' })
				return
			}

			const roomId = helper.generateUUID()

			switch (type) {
				case 'multiPlayer':
					await this.$store.dispatch('createRoom', roomId)
					this.$router.replace({ name: 'Room', params: { id: roomId } })
					this.$router.go()
					break;
			
				default:
					await this.$store.dispatch('playSolo', roomId)
					this.$router.replace({ name: 'games_play', params: { id: roomId } })
					break;
			}
		},
		showModal () {
			this.isModalVisible = true
		},
		showModalRoomMasterLeft() {
			this.$store.commit('SET_MODAL_EXIT_MESSAGE', ['OH NO! YOUR HOST', 'HAS LEFT THE ROOM'])
			this.showModalLeaveWhenRoomMasterLeft = true
		},
		stopListener () {
			off(listeners)
		},
		async validateRoomRules () {
			const roomData = await firebaseRepository.getDataRealtimeDB(`rooms/${this.roomID}`)

			// handle agar munculin modal left room master left ketika room master left dan user sengaja refresh browser
			if (!roomData) {
				this.showModal()
				this.showModalRoomMasterLeft()
				return
			}

			// cek total member
			if (roomData.participant_data.length > 20) {
				this.$store.commit('SET_CONDITION_NEED_CONFIRMATION', false)
				this.unsubscribe()
				setTimeout(() => {
					this.$router.push({ name: "Homepage" })
					alert('Room Penuh')
				}, 500)
			}

			// cek member sudah terdaftar atau belum
			this.$store.dispatch('checkingAvaibilityMember', roomData.participant_data)

			// handling agar tidak ada multi room master dalam 1 room
			if (this.is_room_master) {
				this.$store.dispatch('resetRoomMasterStatus', roomData)
			}
		}
	},
	mounted: function() {
		this.$nextTick(async () => {
			this.$store.commit('SET_ROOM_ID', this.$route.params.id)
			this.$store.commit('SET_PREPARATION_MODAL_VISIBILITY', false)
			this.$store.commit('SET_CONDITION_NEED_CONFIRMATION', true)
			
			// detect when user force close browser
			// window.onbeforeunload = function() {
			// 	return "mau keluar?";
			// }

			await this.getUserProfile(auth.currentUser.uid)
			await this.validateRoomRules()
			await this.listParticipant()
		})
	},
	beforeRouteLeave (to, from, next) {
		if (this.need_confirmation) {
			if (this.to) {
				next()
			} else {
				this.to = to
				this.isModalVisible = true
			}
		} else {
			this.stopListener()
			next()
		}
	}
}
</script>
