import Vue from 'vue'
import VueRouter from 'vue-router'
import VuejsDialog from 'vuejs-dialog'
import Homepage from '../views/Homepage.vue'
import TermCondition from '../views/TermCondition.vue'
import Login from '../views/Login.vue'
import Settings from '../views/Settings.vue'
import Room from '../views/Room.vue'
import Games from '../views/Games.vue'
import Leaderboard from '../views/Leaderboard.vue'
import Receipt from '../views/Receipt.vue'
import Level from '../views/Level.vue'
import ResultGame from '../views/ResultGame.vue'
import GameDone from '../views/GameDone.vue'
import EmailTemplate from '../views/EmailTemplate.vue'
import { auth } from '../firebase/firebaseInit'
Vue.use(VuejsDialog)

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login,
      meta: {
        guest: true
      }
    },
    {
      path: '/homepage',
      name: 'Homepage',
      component: Homepage,
      meta: {
        auth: true
      }
    },
    {
      path: '/terms-and-condition',
      name: 'TermCondition',
      component: TermCondition,
      meta: {
        auth: true
      }
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      meta: {
        auth: true
      }
    },
    {
      path: '/room/:id',
      name: 'Room',
      component: Room,
      meta: {
        auth: true
      }
    },
    {
      path: '/games/:id',
      name: 'games_play',
      component: Games,
      meta: {
        auth: true
      }
    },
    {
      path: '/leaderboard',
      name: 'Leaderboard',
      component: Leaderboard,
      meta: {
        auth: true
      }
    },
    {
      path: '/receipt',
      name: 'Receipt',
      component: Receipt,
      meta: {
        auth: true
      }
    },
    {
      path: '/level',
      name: 'Level',
      component: Level,
      meta: {
        auth: true
      }
    },
    {
      path: '/result-game',
      name: 'Result Game',
      component: ResultGame,
      meta: {
        auth: true
      }
    },
    {
      path: '/done',
      name: 'Game Done',
      component: GameDone,
      meta: {
        auth: true
      }
    },
    {
      path: '/email',
      name: 'Email Template',
      component: EmailTemplate,
      meta: {
        auth: true
      }
    },
    {
      path: '/*',
      component: 404
    }
  ]
})

// HANDLE NAVIGATION DUPLICATION FOR ROTUER PUSH (GLOBALLY)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((error) => console.error(error.message))
}

// ROUTES MIDDLEWARE
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.auth)
  const isAuthenticated = auth.currentUser

  if (requiresAuth && !isAuthenticated) {
    const loginpath = window.location.pathname
    next({ name: 'Login', query: { redirect: loginpath } })
  } else if (!requiresAuth && isAuthenticated) next({ name: 'Homepage', query: { redirect: 'Homepage' } })
  else next()
})

export default router
