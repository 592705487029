import { collection, getFirestore, orderBy, where, query } from "firebase/firestore"
import firebaseRepository from './firebase/firebaseRepository'
import moment from 'moment'

export default {
    startOf(type) {
        return moment().clone().startOf(type).format('YYYY-MM-DD')
    },

    endOf(type) {
        return moment().clone().endOf(type).format('YYYY-MM-DD')
    },

    async calculatePoint(type) {
        let startOfType = this.startOf(type),
            endOfType = this.endOf(type)

        const queries = query(
            collection(getFirestore(), `point_histories`),
            where("created_at", ">=", startOfType),
            where("created_at", "<=", endOfType),
            orderBy("created_at", "desc")
        )
        const result = await firebaseRepository.getDataBaseOn(queries)
        const rawData = this.removeDuplicateData(result).sort((firstOrder, lastOrder) => parseInt(lastOrder.additional_point) - parseInt(firstOrder.additional_point) || firstOrder.seconds - lastOrder.seconds).slice(0, 20)
        
        rawData.forEach( (arrayObject) => {
            const ranking = rawData.map( data => data.id ).indexOf(arrayObject.id)
            arrayObject.ranking = ranking + 1
        })
        
        return rawData
    },

    compileLeaderboard (payload, currentUserId) {
        let userRangking,
            userPoint,
            bestOfThree,
            bestOfTwenty

        const arrayRanking = payload.data().array_of_ranking

        userRangking = arrayRanking.find(data => data.id === currentUserId) === undefined ? null : arrayRanking.find(data => data.id === currentUserId).ranking

        userPoint = arrayRanking.find(data => data.id === currentUserId) === undefined ? 0 : arrayRanking.find(data => data.id === currentUserId).additional_point

        bestOfThree = arrayRanking.slice(0, 3).length > 0 ? firebaseRepository.getNameOfUser(arrayRanking.slice(0, 3)) : null

        bestOfTwenty = arrayRanking.slice(3, 20).length > 0 ? firebaseRepository.getNameOfUser(arrayRanking.slice(3, 20)) : null

        return { best_of_three: bestOfThree, best_of_twenty: bestOfTwenty, my_ranking: userRangking, my_point: userPoint }
    },

    fetchFileURL (payload, callback) {
        const file = payload.target.files[0]
        const fileReader = new FileReader()
        fileReader.onload = callback

        fileReader.addEventListener('load', () => {
            return fileReader.result
        })

        fileReader.readAsDataURL(file)
    },

    findUserIndex (arrayData, id) {
        const index = arrayData.findIndex( data => data.id === id )
        return index
    },

    generateUUID() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    },

    makeSyncronize (task) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(task)
          }, 500)
        })
    },

    // remove duplicate data and sum the additional point
    removeDuplicateData(arrayData) {
        const seen = {},
              order = []
        arrayData.forEach(function(data) {
            const id = data["id"]
            if (id in seen) {
                let additionalPoint = seen[id].additional_point + data.additional_point,
                    second = seen[id].seconds + data.seconds

                seen[id] = data
                seen[id].additional_point = additionalPoint
                seen[id].seconds = second
                order.push(order.splice(order.indexOf(id), 1))
            }
            else {
                seen[id] = data
                order.push(id)
            }
        })

        return order.map(function(query) { return seen[query] })
    }
}
