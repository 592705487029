<template>
	<div class="bg-linear-4 w-full h-full">
		<Button type="back" url="/homepage" name="back" />
		<div class="px-7 pb-11">
			<div class="mt-8 text-center pb-6 border-b border-white">
				<h2 class="text-2xl font-black text-white">DOUBLEMINT® GUM - THE FRESHEST FLICK GAME</h2>
				<h2 class="text-2xl font-black text-white">TERMS AND CONDITIONS</h2>
			</div>
			<div class="mt-7">
				<h3 class="text-base font-bold text-yellow-1">1. RULES & ELIGIBILITY</h3>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">1.1</b> The Doublemint® Gum - The Freshest Flick Game (hereinafter referred to as the “Contest”) is organised by Mars Foods Malaysia Sdn. Bhd. and its third-party agencies, (hereinafter referred to as the “Organiser”). This Contest will be governed by the following terms and conditions and details of the Contest (collectively referred to as the “Terms & Conditions”). Every participant agrees that these Terms & Conditions have been read and understood, and by their participation in the Contest, agrees to be bound by these Terms & Conditions.</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">1.2</b> Subject to applicable law, the decisions of the Organiser for all matters relating to this Contest shall be final and binding in all aspects.</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">1.3</b> Subject to Clause 1.4, the Contest is open to all residents of Malaysia at least fifteen (15) years of age at the time of entry. Participants below the age of eighteen (18) are required to obtain written parental/guardian approval to take part in the Contest, and are to provide that to the Organiser upon request.</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">1.4</b> Employees of Mars Foods Malaysia Sdn. Bhd., and all its third-party agencies, and their affiliates and subsidiaries, including their relatives up to second degree of consanguinity or affinity are disqualified from joining the Contest.</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">1.5</b> All personal details submitted must be accurate, complete, and subject to proof at the request of the Organiser. The Organiser reserves the right, at any time, to verify the validity of any entry and to disqualify any entry that does not comply with these Terms & Conditions or that interferes with the entry process.  Personal data provided in connection with this Contest shall be collected and managed in accordance with the Organiser’s privacy policy, which can be read here: <a href="http://www.mars.com/global/policies/privacy/pp-english" class="text-xs text-yellow-1" target="_blank"> http://www.mars.com/global/policies/privacy/pp-english</a></p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">1.6</b> All participants must consent to provide their personal data such as name, identification number, email and contact number to the Organiser and all the partner of this contest</p>
			</div>
			<div class="mt-6">
				<h3 class="text-base font-bold text-yellow-1">2. DOUBLEMINT® GUM - THE FRESHEST FLICK GAME </h3>
				<p class="mt-2.5 text-sm"><b class="text-yellow-1">2.1 Contest Period</b></p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">2.1.1.</b> The Contest will start on March, 1st 2022 at 00.00 (local time) and end on April, 8th 2022 at 11:59PM (local time) (hereinafter referred to as the “Contest Period”).</p>
				<p class="mt-2.5 text-sm"><b class="text-yellow-1">2.2 How to Join</b></p>
				<p class="mt-2 text-white"><b>Contest Coverage:</b> Nationwide</p>
				<p class="mt-2 text-white font-bold">Mechanism:</p>
				<ol>
					<li class="text-sm text-white">Login to Doublemint® - The Freshest Flick Game site at <a href="https://my.lettherebefresh.com/" class="underline" target="_blank">my.lettherebefresh.com</a> </li>
					<li class="text-sm text-white">Swipe to flick gums out of a Doublemint Gum pack</li>
					<li class="text-sm text-white">
						A limited time is set for each challenge, yet the amount of gums that need to be flicked remain the same
					</li>
					<li class="text-sm text-white">Each participant can play solo or invite their friends to join the game. The participants can get additional points if they upload a receipt of a Doublemint Gum</li>
					<li class="text-sm text-white">Weekly winners will be announced based on the leaderboard each week. 5 participants who got the highest score by the end of the campaign will receive the grand prize</li>
				</ol>
				<p class="text-sm text-white font-bold mt-4">This Contest is a game of skill and chance plays no part in determining the winners.</p>
				<p class="mt-2.5 text-sm"><b class="text-yellow-1">2.3 Prizes:</b></p>
				<ul>
					<li class="text-sm text-white">Champion (top score): Five (5) AirPod Max</li>
					<li class="text-sm text-white">Weekly winners: Twenty (40) Lazada Gift Cards.</li>
					<li class="text-sm text-white">Consolation Prize: One Thousand (1000) Grab Gift Cards.</li>
				</ul>
				<p class="mt-2.5 text-sm"><b class="text-yellow-1">2.4 Redemption and Communication of Winners:</b></p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">2.4.1.</b> Authorised representative of Doublemint® Gum will contact the winners via call or email, asking for the following information:</p>
				<p class="text-white text-sm">(1) Name</p>
				<p class="text-white text-sm">(2) Identification Card Number</p>
				<p class="text-white text-sm">(3) Contact Number</p>
				<p class="text-white text-sm">(4) E-mail</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">2.4.2.</b> The winning participants must complete the verification process by sending of the personal information as identified in paragraph 2.4.1 (including a photocopy of identification card) and original winning receipt</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">2.4.3.</b> If any of the winners are not contactable/ disqualified, the next entry with the highest score will be selected as a replacement winner</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">2.4.4.</b> Appointed agency will send the prize to winner via post to the address provided by winner</p>
			</div>
			<p class="mt-2.5 text-sm text-white"><b class="text-sm text-yellow-1">2.4.5</b> Winner will be announced in the following date:</p>
			<ul>
				<li class="text-sm text-white">
					Weekly prize announcement
					<ul>
						<li class="text-sm text-white">March, 15th 2002</li>
						<li class="text-sm text-white">March, 22nd 2002</li>
						<li class="text-sm text-white">March, 29th 2002</li>
						<li class="text-sm text-white">April, 5th 2022</li>
					</ul>
				</li>
				
				<li class="text-sm text-white">
					Grand prize announcement 
					<ul>
						<li class="text-sm text-white">April, 8th 2022</li>
					</ul>
				</li>
				<li class="text-sm text-white">
					Consolation prize announcement
					<ul>
						<li class="text-sm text-white">April, 8th 2022</li>
					</ul>
				</li>
			</ul>
			<div class="mt-6">
				<h3 class="text-base font-bold text-yellow-1">3. DISQUALIFICATION</h3>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">3.3 </b> 
					<b>The Organiser reserves the right to disqualify any participants based on the following:</b>
				</p>
				<ul>
					<li class="text-sm text-white">If the contestant is below the minimum age requirement of fifteen (15) years of age.</li>
					<li class="text-sm text-white">If the contestant is below eighteen (18) years old and has not provided a written parental/guardian consent, when requested by the Organiser.</li>
				</ul>
			</div>
			<div class="mt-6">
				<h3 class="text-base font-bold text-yellow-1">4. RIGHTS OF THE ORGANISER</h3>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">4.3.1.</b> All entries, including the winners receiving the prize, may be featured on the Doublemint® Gum - The Freshest Flick Game site, without further compensation, approval, or notice.</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">4.3.2.</b> Prizes are not convertible to cash.</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">4.3.3.</b> The Organiser, based on these Terms & Conditions, may disqualify any entries and prohibit further participation in the Contest by any person whom the Organiser reasonably believes has tampered with the entry process or with the operation of the Contest or who has acted in violation of these Terms and Conditions or in a disruptive manner or for any other reason.</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">4.3.4.</b> Participants agree to be bound by the decisions of the Organiser.</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">4.3.5.</b> The Organiser may require participants to validate their entry in the Contest, or to provide more information to allow the Organiser to verify the participants’ participation, at any time during the Contest prior to being eligible to redeem any prize.</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">4.3.6.</b> Acceptance of any prize shall constitute a release and discharge of the Organiser from the  winner concerned from any and all liability, claims, demands, causes of action, and/or damages which the winner may have, whether known or unknown at the present time, of any nature whatsoever, arising out of or relating to: (i) the Contest, (ii) personal injury and/or property damage, theft or loss suffered by the winner (and/or their companions and/or pets) as a result of the use and/or enjoyment of the prize, and/or (iii) any tax liabilities in relation to the Contest, prize and/or use or enjoyment of the same.</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">4.3.7.</b> If for any reason any aspect of this Contest is not capable of running as planned, including by reason of infection by computer virus, network failure, bugs, tampering, unauthorised intervention, fraud, cheating, technical failures or any causes beyond the control of the Organiser which corrupts or affects the administration, security, fairness, integrity or proper conduct of this Contest, then subject to applicable laws and regulations, the Organiser reserves the right in its sole discretion to revoke the prize, replace the winner, cancel, terminate, modify or suspend the Contest, or invalidate any affected entries.</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">4.3.8.</b> Subject to applicable law and regulations, the Organiser’s decision on any matter concerning the Contest is final with respect to any aspect of the Contest, including the determination of the winners. No correspondences or appeals will be entertained.</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">4.3.9.</b> These Terms & Conditions are governed by the laws of Malaysia. Any dispute shall be subject to the jurisdiction of the Courts in Malaysia.</p>
				<!-- <p class="text-white text-sm mt-2.5"><b class="text-yellow-1">4.3.10.</b> If there is any inconsistency or ambiguity between the English 
					version and the Thai version, the English version shall prevail.
				</p>
				<p class="text-white text-sm mt-2.5"><b class="text-yellow-1">4.3.11.</b> These Terms & Conditions are governed by the laws of Thailand. 
					Any dispute shall be subject to the jurisdiction of the Courts in Thailand.
				</p> -->
			</div>
		</div>
	</div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
	name: 'TermCondition',
	components: {
		Button,
	}
}
</script>

<style scoped>

body {
	overflow: auto !important;
}

</style>