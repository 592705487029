<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div
        class="bg-green-8 h-screen relative text-center"
        @click="continueGame()"
      >
        <div class="absolute left-0 right-0 top-20 w-full mx-auto">
          <img class="mx-auto" src="../assets/images/icon-emote4.svg" alt="" />
          <div class="mt-5 mx-10">
              <!-- TODO:
                - Kondisi copytext easy breazy
                - dynamic emoticon senang / sedih
                - point
              -->
            <h2 class="text-6xl text-white font-bold">{{ title }}</h2>
            <p class="text-xs text-white mt-2">{{ description }}</p>
            <span class="text-2xl text-white font-black"
              >LEVEL {{ level }} COMPLETED</span
            >
          </div>
          <div 
            class="mt-10 mx-20 py-3 px-5 bg-green-5 rounded-3xl relative"
            style="box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.3);"
          >
            <img
              class="absolute left-0 right-0 -top-5 mx-auto"
              src="../assets/images/star-white-big.svg"
              alt=""
            />
            <h1
              class="text-9xl text-green-3 font-black"
              style="-webkit-text-stroke: 7px white; text-shadow: 5px 8px 10px rgb(0 0 0 / 40%);"
            >
              {{ totalPoint }}
            </h1>
            <p class="text-white text-xl">points earned</p>
          </div>
        </div>
        <div class="absolute left-0 right-0 bottom-0">
          <img class="w-full" src="../assets/images/game-level-complete-coffee.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Howl, Howler } from 'howler';
export default {
  name: "ModalLevelComplete",
  props: [
    'level',
    'pointLevel1',
    'pointLevel2',
    'pointLevel3',
  ],
  data() {
    return {
      totalPoint: 0,
      title: '',
      description: '',
    }
  },
  created() {
    this.$store.commit('PLAY_AUDIO', false)
    this.$parent.$parent.sfx.gameLevelComplete.play()
    
    // Howler.unload();
    
    switch (this.level) {
      case 1:
        this.title = 'EASY BREEZY!';
        this.description = 'DRINK SOME, WIN SOME';
        this.totalPoint = this.pointLevel1;
        break;
      case 2:
        this.title = 'SO FRESH!';
        this.description = 'MINTS & COFFEE FRESHEN UP THE MOOD';
        this.totalPoint = this.pointLevel2;
        break;
      case 3:
        this.title = 'SUPER COOL!';
        this.description = 'THAT CAFFEINE HIT SURELY KICKS IN';
        this.totalPoint = this.pointLevel3;
        break;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    continueGame() {
      // this.$parent.$parent.sfx.touch.play()
      this.$emit("continueGames");
    },
  },
};
</script>
