<template>
	<div :class="{ loader: true, fadeout: !isLoading }">
		<div class="fixed left-0 right-0 top-2/6">
			<img class="mx-auto" src="../assets/images/get-ready-coffee.png" alt="">
		</div>
	</div>
</template>

<style scoped>
	.loader {
		background-image: url('../assets/images/screen-homepage.jpg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		bottom: 0;
		display: block;
		left: 0;
		overflow: hidden;
		position: fixed;
		right: 0;
		top: 0;
	}

	.fadeout {
		animation: fadeout 2s forwards;
	}

		@keyframes fadeout {
		to {
			opacity: 0;
			visibility: hidden;
		}
	}
</style>

<script>
export default {
	name: 'LoadingScreen',
	props: ['isLoading']
}
</script>
