<template>
	<div class="pages h-full min-h-500">
		<Button type="back" url="/homepage" name="back" />
		<form class="text-center mt-16 mx-14" action="#">
			<div class="relative" v-if="photoPreview">
				<img class="mx-auto mb-5" :src="photoPreview" height="200" width="200">
				<img src="../assets/images/close-red.svg" class="absolute right-4 -top-4" alt="" @click="cancelPhoto">
			</div>
			<div class="flex flex-col gap-4">
				<div class="flex gap-2 items-center w-full">
					<label for="upload" class="bg-green-5 p-2.5 rounded-2xl w-14 h-12 flex items-center justify-center"><img class="w-full" src="../assets/images/upload.png" alt=""></label>
					<input type="file" id="upload" class="hidden" accept="image/*" @change="onSelectedFile">
					<button type="button" class="bg-white rounded-2xl w-full py-2.5 flex justify-center shadow-2xl" @click="onTakePhoto"><img src="../assets/images/camera-upload.svg" alt=""></button>
				</div>
				<input type="file" class="hidden" ref="filePhoto" accept="image/*" capture="environment" @change="onSelectedFile">
				<p v-if="loading" class="text-white">Loading...</p>
				<button type="submit" class="text-sm text-white font-bold bg-green-5 py-3 rounded-2xl" v-if="uploadButton" @click="onUpload()">Upload</button>
			</div>
			<p class="mt-5 text-xl text-white font-bold">UPLOAD YOUR RECEIPT</p>
			<p class="mt-2 text-sm text-white">Upload your Doublemint Gum receipt to boost your points by 20% before 14 March 2022</p>
			<p class="mt-4 text-sm text-white italic">Only valid from selected merchants</p>
		</form>
		<div class="pages__receipt mt-11 md:mt-14">
			<div class="text-center">
				<p class="text-lg text-white font-bold mt-7">CONVENIENCE STORE</p>
				<p class="text-xs text-white font-bold">1-2-2022</p>
			</div>
			<div class="mt-14">
				<div class="flex justify-between items-center">
					<p class="text-xs text-white">Item</p>
					<p class="text-xs text-white">Qty</p>
				</div>
				<div class="flex justify-between items-center">
					<p class="text-xs text-white">Doublemint Gum</p>
					<p class="text-xs text-white">1</p>
				</div>
				<div class="border-2 border-yellow-1 rounded mt-8"></div>
				<div class="flex justify-between items-center mt-2">
					<p class="text-xs text-white">Total</p>
					<p class="text-sm font-black text-white">Extra 20% Points</p>
				</div>
				<div class="mt-5">
					<p class="text-xs text-white">Reminder:</p>
					<ul>
						<li class="text-xs text-white">Receipt must not be repeated with previous uploaded receipt.</li>
						<li class="text-xs text-white">Not valid for e-receipt/digital receipt.</li>
						<li class="text-xs text-white">Only valid for printed copy from selected merchants.</li>
						<li class="text-xs text-white">Terms & Conditions Apply.</li>
					</ul>
				</div>
				<!-- <div class="mt-16 flex justify-between">
					<div>
						<p class="text-xs text-white">Ps:</p>
						<p class="text-xs text-white">Upload <b>one receipt per week</b></p>
						<p class="text-xs text-white">Only valid from selected merchants</p>
					</div>
					<img class="w-20 h-20" src="../assets/images/seven-eleven.png" alt="">
				</div> -->
			</div>
		</div>
		<audio-component></audio-component>
	</div>
</template>

<script>
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { auth, firestore } from "../firebase/firebaseInit"
import { mapActions, mapState } from "vuex"
import Button from '@/components/Button.vue'
import AudioComponent from '@/components/AudioComponent.vue'

let listener

export default {
	name: 'Receipt',
	components: {
		Button,
		AudioComponent
	},
  	computed: {
		...mapState([
			"loading",
			"uploadButton",
			"photoPreview"
		])
	},
	data () {
		return {
			hasUploadedReceipt: false
		}
    },
	methods: {
		...mapActions([
			"onSelectedFile",
			"uploadReceipt",
			"cancelPhoto"
		]),
		userProfile() {
			const profilesQuery = query(
				collection(firestore, `users`),
				where("id", "==", auth.currentUser.uid)
			)
			listener = onSnapshot(profilesQuery, (query) => {
				query.docs.map((data) => {
					this.hasUploadedReceipt = data.data().has_uploaded_receipt
					if (data.data().has_uploaded_receipt) { 
						this.$store.commit('SET_LOADING', false)
						alert('File upload successful')
						this.$router.replace({ name: 'Homepage' })
					}
				})
			})
		},
		onTakePhoto() {
			this.$refs.filePhoto.click()
		},
		async onUpload() {
			await this.uploadReceipt(this.hasUploadedReceipt)
		},
		stopListener() {
			listener()
		}
	},
	created() {
		this.userProfile()
		this.$store.commit("RESET_PHOTO_PREVIEW")
    },
	beforeRouteLeave(to, from, next) {
		this.stopListener()
		next()
	}
}
</script>
