<template>
  <div class="wrapper" :data-open="state === 'open' ? 1 : 0">
    <div class="bg" @click="() => setState('half')"></div>
    <div
      ref="card"
      class="card"
      :class="state"
      :data-state="isMoving ? 'move' : state"
      :style="{ top: `${isMoving ? y : calcY()}px` }"
    >
      <div class="pan-area" ref="pan">
        <div class="bar" ref="bar"></div>
        <slot></slot>
      </div>
      <!-- <div class="contents">
      </div> -->
    </div>
  </div>
</template>

<script>
import Hammer from "hammerjs";

export default {
  name: "bottom-sheet",

  props: {
    openY: {
      type: Number,
      default: 0.25,
    },

    halfY: {
      type: Number,
      default: 0.8, //default: 0.94 chrome: 0.8: safari: 
    },

    defaultState: {
      type: String,
      default: "half",
    },

    value: {
      type: String,
      default: "half",
    },

    minState: {
      type: String,
      default: "half",
    },
  },

  data() {
    return {
      mc: null,
      y: 0,
      startY: 0,
      isMoving: false,
      state: this.defaultState,
      rect: {},
      states: ["half", "open"],
    };
  },

  mounted() {


    var ua = window.navigator.userAgent;
		var iOS = ua.match(/iPad/i) || ua.match(/iPhone/i);
		var webkit = ua.match(/WebKit/i);
		var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

		if (iOSSafari) {
      this.halfY = 0.83; 
      this.openY = 0.16;
		} else {
      this.halfY = 0.8; 
      this.openY = 0.18;
    }

    window.onresize = () => {
      this.rect = this.$refs.card.getBoundingClientRect();
    };

    this.rect = this.$refs.card.getBoundingClientRect();

    this.mc = new Hammer(this.$refs.pan);
    this.mc.get("pan").set({ direction: Hammer.DIRECTION_ALL });

    this.mc.on("panup pandown", (evt) => {
      this.y = evt.center.y - 16;
    });

    this.mc.on("panstart", (evt) => {
      this.startY = evt.center.y;
      this.isMoving = true;
    });

    this.mc.on("panend", (evt) => {
      this.isMoving = false;

      switch (this.state) {
        case "half":
          if (this.state === "close") {
            if (this.startY - evt.center.y > 120) {
              this.state = "half";
            }
            if (this.startY - evt.center.y > 320) {
              this.state = "open";
            }
            break;
          }
          if (this.startY - evt.center.y > 120) {
            this.state = "open";
          }
          break;
        case "open":
          if (this.startY - evt.center.y < -120) {
            this.state = "half";
          }
          break;
        default:
          this.state = "half";
      }

      this.checkForMinState();
    });

    this.checkForMinState();
  },

  beforeDestroy() {
    this.mc.destroy();
    window.onresize = null;
  },

  methods: {
    calcY() {
      switch (this.state) {
        case "close":
          return this.rect.height;
        case "open":
          return this.rect.height * this.openY;
        case "half":
          return this.rect.height * this.halfY;
        default:
          return this.y;
      }
    },

    checkForMinState() {
      if (
        this.states.indexOf(this.state) < this.states.indexOf(this.minState)
      ) {
        this.state = this.minState;
      }
    },

    setState(state) {
      this.state = state;
    },
  },

  watch: {
    value(newState) {
      this.setState(newState);
    },

    state(newState) {
      // if (this.sheetState !== newState) {
      //   this.$emit("input", newState);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper[data-open="1"] {
  position: fixed;
  top: 0;
  left: 0;
}

.wrapper[data-open="1"] .bg {
  display: block;
  transition: all 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.card {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: linear-gradient(150.68deg, #015A37 7.97%, #002E1C 86.28%);;
  border-radius: 38px 38px 0 0;
  box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.1);
  left: 0;
}

.card[data-state="half"],
.card[data-state="open"] {
  transition: top 0.3s ease-out;
}

.bar {
  width: 29px;
  border: 2px solid #fff;
  border-radius: 14px;
  margin: 0 auto;
  cursor: pointer;
}

.pan-area {
  top: 0;
  right: 0;
  left: 0;
  position: absolute;
  padding: 12px 0 12px 0;
  .bar {
    &:hover {
      cursor: grab;
    }
    &:active {
      cursor: grabbing;
    }
  }
}

.contents {
  margin-top: 28px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  padding-bottom: calc(100vh * 0.2);
  box-sizing: border-box;
}

.card.half h4 {
  font-size: 12px !important;
}
.card.open h4 {
  font-size: 20px !important;
  margin-top: 25px;
}
.card.open .bar {
  margin-top: 8px;
}

</style>