<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div class="bg-green-8 h-screen w-full relative" style="z-index:99999;">
        <div
          class="absolute right-0 left-0 w-full text-center max-w-sm mx-auto"
          style="top:7%;"
        >
          <h2 class="text-6xl text-white font-black mb-4">LEVEL {{ level }}</h2>
          <div class="relative">
            <div
              class="rounded-full bg-white w-40 h-40 mx-auto p-2 mb-5 shadow-2xl"
            >
              <div class="flex flex-col justify-center">
                <img
                  class="w-4 h-4 mx-auto"
                  src="../assets/images/star-green.svg"
                  alt=""
                />
                <span class="text-lg font-bold text-green-4">Flick</span>
                <h2 class="text-7xl text-green-5 font-black">{{ gums }}</h2>
                <span class="text-lg font-bold text-green-4">Gums</span>
              </div>
            </div>
            <div class="slash"></div>
            <div class="rounded-full bg-green-5 w-28 h-28 ml-14 p-4 shadow-2xl">
              <div class="flex flex-col justify-center">
                <img
                  class="w-5 h-5 mx-auto"
                  src="../assets/images/icon-bell.svg"
                  alt=""
                />
                <span class="text-xs font-bold text-white-3">COLLECT</span>
                <h3 class="text-4xl font-bold text-white">{{ points }}</h3>
                <span class="text-white-3" style="font-size: 9px">points</span>
              </div>
            </div>
            <div class="slash-2"></div>
            <div
              class="absolute -bottom-1/4 right-0 rounded-full bg-black w-28 h-28 mr-14 p-4 shadow-2xl"
            >
              <div class="flex flex-col justify-center">
                <img
                  class="w-5 h-5 mx-auto"
                  src="../assets/images/icon-hour.svg"
                  alt=""
                />
                <span class="text-xs font-bold text-white-3">IN</span>
                <h3 class="text-4xl font-bold text-white">{{ seconds }}</h3>
                <span class="text-white-3" style="font-size: 9px">seconds</span>
              </div>
            </div>
          </div>
        </div>
        <div class="absolute left-0 right-0 bottom-1/14 text-center">
          <p class="text-xs font-black text-white">Ready to play in</p>
          <span class="text-white font-black mb-8 text-7xl">{{ preparationTimer }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalPreparation",
  props: ["level", "preparationTimer"],
  data() {
    return {
      gums: 50,
      points: 0,
      seconds: 0,
    };
  },
  created() {
    switch (this.level) {
      case 2:
        this.points = 20;
        this.seconds = 30;
        break;
      case 3:
        this.points = 30;
        this.seconds = 10;
        break;
      default:
        this.points = 10;
        this.seconds = 50;
        break;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
