import { auth, database, firestore, facebook, google, serverTime, storage } from "../firebase/firebaseInit"
import { signInWithPopup, signOut } from "firebase/auth"
import { child, get, ref, set, update } from "firebase/database";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc, query, where } from "firebase/firestore"
import { ref as sRef, getDownloadURL, uploadBytesResumable } from 'firebase/storage'

export default {
    async signIn (type) {
        await signInWithPopup(auth, type === 'Google' ? google : facebook)
    },

    async logOut () {
        await signOut(auth)
    },

    async getDocumentOf (tableName, id) {
        const docRef = doc(firestore, tableName, id)
        const docSnap = await getDoc(docRef)
        return docSnap
    },

    async updateDocument (tablePath, payload) {
        await setDoc(doc(firestore, tablePath), payload, { merge: true })
    },

    // TIDAK PERLU MENAMBAHKAN/MENENTUKAN ID (ID DIISI/DITENTUKAN OLEH FIREBASE)
    async addDocument (tableName, payload) {
        await addDoc(collection(firestore, tableName), payload)
    },

    // PERLU MENAMBAHKAN/MENENTUKAN ID
    async setDocument (tablePath, payload) {
        await setDoc(doc(firestore, tablePath), payload)
    },

    async deleteDocument (tableName, id) {
        await deleteDoc(doc(firestore, tableName, id))
    },

    storeImage(folderName, payload) {
        let progressBar
        let dataProfile
        const storageRef = sRef(storage, `${folderName}/${payload.name}`)
        const uploadTask = uploadBytesResumable(storageRef, payload, {contentType: payload.type})
        
        uploadTask.on('state-change', (snapshot) => {
            progressBar = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        (error) => {
            alert(`error : ${error.message}`)
        },
        () => {
            getDownloadURL(storageRef).then( async (url) => {

                const dataHistoryUploadReceipt = {
                    id: auth.currentUser.uid,
                    title: payload.name,
                    size: payload.size,
                    type: payload.type,
                    status: 'Not yet approved',
                    created_at: new Date().toISOString(),
                    download_url: url
                }
                if (folderName == "Receipts") {
                    const createHistoryUpload = await this.addDocument("receipt_histories", dataHistoryUploadReceipt)
    
                    dataProfile = { has_uploaded_receipt: true, newest_recipt_image: url }
                } else {
                    dataProfile = { photo_profile_url: url }
                }
                const updateUser = await this.updateDocument(`users/${auth.currentUser.uid}`, dataProfile)
            })
        })
        return progressBar
    },

    async getDataBaseOn(query) {
        let arrayData = []
        const queryData = await getDocs(query)
        queryData.docs.map( data => {
            arrayData.push(data.data())
        })
        return arrayData
    },

    getNameOfUser (array) {
        const arrayData = []
        array.forEach(async data => {
            const profile = await this.getDocumentOf('users', data.id)
            data.name = profile.data().name
            data.photoProfile = profile.data().photo_profile_url
            data.lastRankingWeekly = profile.data().last_ranking_weekly
            data.lastRankingMonthly = profile.data().last_ranking_monthly
            arrayData.push(data)
        })
        return arrayData
    },

    async updateRoomMaster (payload) {
        const roomMaster = query(
            collection(firestore, 'room_subcribers'),
            where('room_master_id', '==', auth.currentUser.uid)
        )

        const queryRoomMaster = await getDocs(roomMaster)
        queryRoomMaster.docs.map((doc) => {
            updateDoc(doc.ref, payload)
        })
    },

    // realtime DB
    createRealtimeDB (tablePath, payload) {
        set(ref(database, tablePath), payload)
    },

    getDataRealtimeDB (pathName) {
        return get(child(ref(database), pathName)).then((snapshot) => {
            return snapshot.val()
        }).catch((error) => {
            console.error(error)
        })
    },

    updateTimer (typeOf, payload) {
        let updates = {}

        updates[`rooms/${payload.roomID}/${typeOf}_timer_start_at`] = payload.status === 'start' ? serverTime : null
        updates[`rooms/${payload.roomID}/seconds_of_${typeOf}_timer`] = payload.seconds
        
        if (typeOf === 'preparation') {
            updates[`rooms/${payload.roomID}/is_preparing_to_start`] = payload.status === 'start' ? true : false
        } else { 
            updates[`rooms/${payload.roomID}/is_game_timer_start`] = true
            updates[`rooms/${payload.roomID}/is_preparing_to_start`] = payload.status === 'start' ? false : true
        }

        update(ref(database), updates)
    },
}
