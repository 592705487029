<template>
	<!-- <div class="done">
		<div class="absolute left-0 right-0 top-1/10 w-full mx-auto text-center">
			<img class="mx-auto" src="../assets/images/icon-emote4.svg" alt="">
			<div class="mt-5">
				<h2 class="text-6xl text-white font-bold">EASY</h2>
				<h2 class="text-6xl text-white font-bold">BREAZY!</h2>
				<span class="text-2xl text-white font-black">LEVEL 1  COMPLETED</span>
			</div>
			<div class="mt-10 mx-20 py-3 px-5 bg-green-5 rounded-3xl relative">
				<img class="absolute left-0 right-0 -top-5 mx-auto" src="../assets/images/star-white-big.svg" alt="">
				<h1 class="text-9xl text-green-3 font-black" style="-webkit-text-stroke: 7px white;">60</h1>
				<p class="text-white text-xl">points earned</p>
			</div>
		</div>
	</div> -->
	<div>
		<form class="text-center mt-16 mx-14" action="#" @submit.prevent="update">

			<input type="date" class="mb-5 p-2.5 rounded-2xl h-12" :value="date" placeholder="date">

			<div class="relative" v-if="selectedImage.length !== 0">
				<div v-for="item in selectedImage" :key="item.id">
					<figure v-if="item.id == 'top_price'">
						<img class="mx-auto" :src="item.photo_preview" :alt="item.name_photo_preview" height="200" width="200">
						<figcaption>{{ item.name_photo_preview }}</figcaption>
					</figure>
				</div>
			</div>
			
			<!-- Top Price -->
			<label for="top_price" class="bg-green-5 p-2.5 rounded-2xl h-12 flex items-center justify-center">Select Image</label>
			<input type="file" id="top_price" class="hidden" accept="image/*" @change="setPhotoFiles($event)">
			<textarea type="text" id="copytext_top_price" class="mb-5 p-2.5 rounded-2xl h-12" placeholder="top price">{{ topCopytext }}</textarea>

			<div class="relative" v-if="selectedImage.length !== 0">
				<div v-for="item in selectedImage" :key="item.id">
					<figure v-if="item.id == 'weekly_price'">
						<img class="mx-auto" :src="item.photo_preview" :alt="item.name_photo_preview" height="200" width="200">
						<figcaption>{{ item.name_photo_preview }}</figcaption>
					</figure>
				</div>
			</div>

			<!-- Weekly Price -->
			<label for="weekly_price" class="bg-green-5 p-2.5 rounded-2xl h-12 flex items-center justify-center">Select Image</label>
			<input type="file" id="weekly_price" class="hidden" accept="image/*" @change="setPhotoFiles($event)">
			<textarea type="text" id="copytext_weekly_price" class="mb-5 p-2.5 rounded-2xl h-12" placeholder="weekly price">{{ weeklyCopytext }}</textarea>

			<div class="relative" v-if="selectedImage.length !== 0">
				<div v-for="item in selectedImage" :key="item.id">
					<figure v-if="item.id == 'consolidation_price'">
						<img class="mx-auto" :src="item.photo_preview" :alt="item.name_photo_preview" height="200" width="200">
						<figcaption>{{ item.name_photo_preview }}</figcaption>
					</figure>
				</div>
			</div>

			<!-- Consolidation Price -->
			<label for="consolidation_price" class="bg-green-5 p-2.5 rounded-2xl h-12 flex items-center justify-center">Select Image</label>
			<input type="file" id="consolidation_price" class="hidden" accept="image/*" @change="setPhotoFiles($event)">
			<textarea type="text" id="copytext_consolidation_price" class="mb-5 p-2.5 rounded-2xl h-12" placeholder="consolidation price">{{ consolidationCopytext }}</textarea>

			<div>
				<button type="submit" class="text-sm text-white font-bold bg-green-5 py-3 rounded-2xl">Submit</button>
			</div>
			<p v-if="progressBar">{{ progressBar }} %</p>
		</form>
	</div>
</template>

<script>
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import firebaseRepository from '../firebase/firebaseRepository'
import helper from '../helper';

export default {
	data () {
		return {
			selectedImage: [],

			date: null,
			progressBar: null,
			topCopytext: null,
			weeklyCopytext: null,
			consolidationCopytext: null
		}
	},
	methods: {
		setPhotoFiles (payload) {
			let id = payload.target.id,
				file = payload.target.files[0],
				data = {
					id: id,
					file: file,
					name_photo_preview: id.split('_').join(' '),
					photo_preview: URL.createObjectURL(file)
				},
				index = helper.findUserIndex(this.selectedImage, id)

			if (index === -1) this.selectedImage.push(data)
			else {
				const selected = this.selectedImage.filter((result) => { return result.id !== id })
				this.selectedImage = selected
				this.selectedImage.push(data)
				// this.selectedImage[index] = data
			}
		},
		update (event) {
			let date = event.target[0].value,
				topPriceCopytext = event.target[2].value,
				weeklyPriceCopytext = event.target[4].value,
				consolidationPriceCopytext = event.target[6].value,
				indexTopPrice = helper.findUserIndex(this.selectedImage, 'top_price'),
				indexWeeklyPrice = helper.findUserIndex(this.selectedImage, 'weekly_price'),
				indexConsolidationPrice = helper.findUserIndex(this.selectedImage, 'consolidation_price')
				
			if (this.selectedImage.length !== 3 || !date || !topPriceCopytext || !weeklyPriceCopytext || !consolidationPriceCopytext) return alert('all field is required')

			this.selectedImage[indexTopPrice]['copytext'] = topPriceCopytext
			this.selectedImage[indexWeeklyPrice]['copytext'] = weeklyPriceCopytext
			this.selectedImage[indexConsolidationPrice]['copytext'] = consolidationPriceCopytext


			for (let i = 0; i < this.selectedImage.length; i++) this.storeImage(this.selectedImage[i], date )

		},
		storeImage (payload, date) {
			const storage = getStorage();
			const storageRef = ref(storage, `Grandprice/${payload.file !== undefined ? payload.file.name : null}`);

			const uploadTask = uploadBytesResumable(storageRef, payload.file !== undefined ? payload.file : null, {contentType: payload.file !== undefined ? payload.file.type : null});

			uploadTask.on('state_changed',
				(snapshot) => {
					this.progressBar = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
				},
				(error) => {
					alert(error.message)
				},
				() => {
					getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
						let update = {},
							key = payload.id

						update['date'] = date
						update[key] = {
							id: key,
							copytext: payload.copytext,
							image: payload.file !== undefined ? downloadURL : payload.photo_preview
						}

						await firebaseRepository.updateDocument(`grandprices/data`, update)
					});
				}
			);
		}
	},
	async created() {
		const grandprice = await firebaseRepository.getDocumentOf('grandprices', 'data')
		if (grandprice.data() !== undefined) {
			this.date = grandprice.data().date
			this.topCopytext = grandprice.data().top_price.copytext
			this.weeklyCopytext = grandprice.data().weekly_price.copytext
			this.consolidationCopytext = grandprice.data().consolidation_price.copytext
			
			this.selectedImage = [
				{ id: 'top_price', name_photo_preview: 'top price', photo_preview: grandprice.data().top_price.image }, 
				{ id: 'weekly_price', name_photo_preview: 'weekly price', photo_preview: grandprice.data().weekly_price.image }, 
				{ id: 'consolidation_price', name_photo_preview: 'consolidation price', photo_preview: grandprice.data().consolidation_price.image }
				]
		}
	}
}
</script>
