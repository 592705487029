<template>
  <div class="main">
    <div class="absolute left-0 right-0 top-5 ml-5 mr-14 flex items-center gap-3 justify-between">
      <router-link :to="{ name: 'Settings' }">
        <div class="w-11 h-11" @click="$parent.sfx.touch.play()">
          <img class="rounded-full w-full h-full object-cover" :src="photoPreview ? photoPreview : require('../assets/images/slide-example.png')" alt="" />
        </div>
      </router-link>
      <div class="bg-white rounded-2xl py-4 px-5 shadow-3xl w-full">
        <div class="flex justify-between items-center">
          <p class="text-xs font-bold">Hi, {{ name }}</p>
          <div class="flex gap-1">
            <div class="life-full" v-for="index in life" :key="`life-full${index}`">
              <img src="../assets/images/life-full.svg" alt="">
            </div>
            <div class="life-empty" v-for="index in 3 - life" :key="`life-empty${index}`">
              <img src="../assets/images/life-empty.svg" alt="">
            </div>
          </div>
        </div>
        <p class="text-base font-bold text-red-1" style="font-size: 14px;text-align: center;margin-top: 4px;">{{ life !== 0 ? `You have ${life } lives remaining` : `Your life refresh in ${resetLifeTimer}` }}</p>
      </div>
    </div>
    <div class="fixed left-0 right-0 top-1/12">
      <img src="../assets/images/home-get-ready-flick-malaysia.png" alt="" style="width: 75%;margin: 0 auto;">
    </div>
    <div class="absolute bottom-0 left-0 right-0" style="z-index:2">
      <div class="mx-5 mb-4 w-52 mx-auto">
        <router-link :to="{ name: 'Receipt' }" class="rounded-2xl bg-green-3 py-2 flex items-center justify-center gap-2" v-if="!has_uploaded_receipt">
          <img src="../assets/images/camera.svg" alt="">
          <div @click="$parent.sfx.touch.play()">
            <p class="text-xs font-bold text-white">UPLOAD RECEIPT &</p>
            <p class="text-xs font-bold text-white">BUMP UP 20% POINTS</p>
          </div>
        </router-link>
        <div class="rounded-2xl bg-white-5 py-2 px-4 flex items-center justify-center gap-2 shadow-5xl" v-else>
          <img src="../assets/images/camera.svg" alt="">
          <div @click="$parent.sfx.touch.play()">
            <p class="text-xs text-white">You have uploaded</p>
            <p class="text-xs text-white">the receipt for this week</p>
          </div>
        </div>
      </div>
      <div class="bg-purple-1 rounded-t-xxl">
        <div @click="$parent.sfx.touch.play()">
        <router-link to="/leaderboard" class="py-3 pl-7 pr-10 w-full text-center flex justify-between">
          <div class="flex items-center">
            <img class="w-10 h-10" src="../assets/images/star.svg" alt="">
            <p class="text-yellow-1 text-lg font-bold">LEADERBOARD</p>
          </div>
          <div class="flex items-center gap-2.5">
            <p class="text-white text-sm font-bold">{{ myPoint }} POINTS</p>
            <img src="../assets/images/arrow-right.svg" alt="">
          </div>
        </router-link>
        </div>
        <div class="bg-linear-5 rounded-t-xxl px-5 pb-5 pt-7 w-full">
          <div class="flex flex-col gap-5">
            <button @click.stop="play('multiPlayer')" class="btn bg-gradient-to-r from-green-500 to-green-700 py-4 block w-full rounded-xl flex items-center justify-center gap-3 font-bold text-base text-white"><img src="../assets/images/party-icon.svg" alt="">Create Room</button>
            <button @click.stop="play('solo')" class="btn bg-gradient-to-r from-green-400 to-green-600 py-4 block w-full rounded-xl flex items-center justify-center gap-3 font-bold text-base text-white"><img src="../assets/images/solo-icon.svg" alt="">Play Solo</button>
          </div>
        </div>
      </div>
    </div>
    <ModalPages
      v-if="isModalVisible = pop_up_homepage"
      @close="closeModal"
    />

    <audio-component></audio-component>

  </div>
</template>

<script>
// import Button from '@/components/Button.vue'
import AudioComponent from '@/components/AudioComponent.vue'
import ModalPages from '@/components/ModalPages.vue'
import firebaseRepository from '../firebase/firebaseRepository'
import helper from '../helper'
import { auth } from '../firebase/firebaseInit'
import { mapActions, mapState } from 'vuex'
import { collection, doc, getDocs, getFirestore, setDoc } from "firebase/firestore"
import moment from 'moment'

import { Howl } from 'howler';

export default {

  components: {
    // Button
    AudioComponent,
    ModalPages
  },
  computed: {
    ...mapState([
      'has_uploaded_receipt',
      'pop_up_homepage',
      'life',
      'myPoint',
      'name',
      'audio_object'
    ]),
    photoPreview () {
      return this.$store.state.photoPreview === null ? require('../assets/images/icon-user.png') : this.$store.state.photoPreview
    }
  },
  data () {
    return {
      isModalVisible: true,
      intervalLifeRemaining: null,
      resetLifeTimer: '00:00:00',
      audio: null,
    }
  },
  methods: {
    ...mapActions([
      'createRoom',
      'playSolo',
      'getUserProfile',
      'signOut'
    ]),
    playSound(sound) {
      this.$parent.sfx.touch.play()
    },
    async countdownLifeRemaining() {
      let tomorrow = moment().add(1, 'day').startOf('day').valueOf(),
          diffTime = tomorrow - moment().valueOf(),
          duration = moment.duration(diffTime, 'milliseconds')

      this.intervalLifeRemaining = setInterval(() => {
        duration = moment.duration(duration - 1000, 'milliseconds')
        let hour = duration.hours() < 10 ? `0${duration.hours()}` : duration.hours(),
            minute = duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes(),
            second = duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds()

        this.resetLifeTimer = `${hour} : ${minute} : ${second}`
      }, 1000)

      if (this.life !== 0) clearInterval(this.intervalLifeRemaining)
    },
    async play(type) {
      this.$parent.sfx.touch.play()
      if (this.life <= 0) {
        return alert('Sorry, you can’t create a room because you don’t have any lives remaining. Try again tomorrow after your coffee break')
      }

      const roomId = helper.generateUUID()
      

      switch (type) {
        case 'multiPlayer':
          await this.$store.dispatch('createRoom', roomId)
          this.$router.push({ name: 'Room', params: { id: roomId } })
          break;
      
        default:
          await this.$store.dispatch('playSolo', roomId)
          this.$router.push({ name: 'games_play', params: { id: roomId } })
          break;
      }
    },
    async closeModal () {
      this.$store.commit('SET_MODAL_VISIBILITY', false)
      await firebaseRepository.updateDocument(`users/${auth.currentUser.uid}`, { pop_up_homepage: false })
    }
  },
  async created () {
    // this.$store.commit('PLAY_AUDIO', true)
    localStorage.setItem("currentTime", 0);

    // const dataJson = { 
    //   last_ranking_monthly: null,
    //   last_ranking_weekly: null,
    //   point: 0,
    // }

    // const querySnapshot = await getDocs(collection(getFirestore(), "users"))
    // querySnapshot.forEach(async (query) => {
    //   // console.log(query.data())
    //   await setDoc(doc(getFirestore(), `users/${query.data().id}`), dataJson, { merge: true })
    // })

    // let dataLeaderboardWeekly = await helper.calculatePoint('weeks')
    // console.log(dataLeaderboardWeekly, 'weekly')

    await this.getUserProfile(auth.currentUser.uid)
    // await firebaseRepository.updateDocument(`users/${auth.currentUser.uid}`, {life: 3})
    this.countdownLifeRemaining()
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.intervalLifeRemaining)
    next()
	}
}
</script>
