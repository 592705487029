<template>
  <div class="settings">
    <Button type="back" url="/homepage" name="back" />
    <div class="absolute bottom-0 left-0 right-0">
      <div class="absolute left-0 right-0 -top-44 flex flex-col text-center">
        <span class="block mb-7 font-bold text-3xl text-white"
          >YOUR PROFILE</span
        >
        <div class="relative w-40 h-40 mx-auto">
          <img
            class="rounded-full w-full h-full object-cover"
            :src="!photoPreview ? require('../assets/default-user.svg') : photoPreview"
            alt=""
          />
          <input type="file" class="hidden" ref="fileInput" accept="image/*" @change="onPreviewImage">
          <button @click="onTakePhoto" class="absolute right-2 bottom-0">
            <img src="../assets/images/edit-photo.png" alt="" />
          </button>
        </div>
      </div>
      <div class="bg-white rounded-t-2xl px-7 pt-20 pb-5 w-full">
        <form action="#" @submit.prevent="updateProfile">
          <div class="mb-5 relative">
            <input
              type="text"
              class="w-full border-b-2 p-3 pl-12"
              placeholder="Your Name"
			        :value="name"
            />
            <img
              class="absolute bottom-4 left-3"
              src="../assets/images/icon_user.svg"
              alt=""
            />
          </div>
          <div class="mb-5 relative">
            <input
              type="email"
              class="w-full border-b-2 p-3 pl-12"
              placeholder="Your Email"
              :value="email"
              readonly
            />
            <img
              class="absolute bottom-4 left-3"
              src="../assets/images/icon_mail.svg"
              alt=""
            />
          </div>
          <div class="mb-5 relative">
            <input
              type="tel"
              class="w-full border-b-2 p-3 pl-12"
              placeholder="Your Phone Number"
              @keypress="validateOnlyNumber($event)"
              :value="phone"
            />
            <img
              class="absolute bottom-4 left-3"
              src="../assets/images/icon_call.svg"
              alt=""
            />
          </div>
          <div class="flex gap-4 pl-2 justify-center">
            <button type="submit" class="bg-green-2 py-2 block w-full rounded-xl font-bold text-white">Update</button>
          </div>
        </form>
        <div class="flex gap-4 pl-2 mt-3">
          <!-- <img src="../assets/images/logout-btn.svg" alt=""> -->
          <button class="bg-red-2 py-2 block w-full rounded-xl font-bold text-white" @click="logOut">Logout</button>
        </div>

      </div>
    </div>

    <audio-component></audio-component>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { auth } from '../firebase/firebaseInit'
import Button from '@/components/Button.vue'
import AudioComponent from '@/components/AudioComponent.vue'

import { Howl } from 'howler';

export default {
  name: 'Settings',
  components: {
    Button,
    AudioComponent,
  },
  computed: {
    ...mapState(['name', 'email', 'phone', 'photoPreview'])
  },
  methods: {
    ...mapActions(['onPreviewImage', 'logOut']),
    validateOnlyNumber (evt) {
      var theEvent = evt || window.event
      if (theEvent.type === 'paste') {
        key = event.clipboardData.getData('text/plain')
      } else {
        var key = theEvent.keyCode || theEvent.which
        key = String.fromCharCode(key)
      }
      var regex = /[0-9]|\./
      if (!regex.test(key)) {
        theEvent.returnValue = false
        if (theEvent.preventDefault) theEvent.preventDefault()
      }
    },
    async updateProfile(event) {
      this.$parent.sfx.touch.play()
      const dataProfile = {
        name: event.target[0].value,
        phone: event.target[2].value
      }
      this.$store.dispatch("updateUserProfile", dataProfile)
    },
    onTakePhoto () {
      this.$refs.fileInput.click()
    }
  },
  created () {
    this.$store.dispatch('getUserProfile', auth.currentUser.uid)
  }
}
</script>
