<template>
	<div class="clean-body u_body" style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #fff;color: #000000">
		<!--[if IE]><div class="ie-container"><![endif]-->
		<!--[if mso]><div class="mso-container"><![endif]-->
		<table style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #fff;width:100%" cellpadding="0" cellspacing="0">
		<tbody>
		<tr style="vertical-align: top">
			<td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
			<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #fff;"><![endif]-->
			

		<div class="u-row-container" style="padding: 0px;background-color: #ffffff">
		<div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 550px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;">
			<div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
			<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: #ffffff;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:550px;"><tr style="background-color: #ffffff;"><![endif]-->
			
		<!--[if (mso)|(IE)]><td align="center" width="550" style="width: 550px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
		<div class="u-col u-col-100" style="max-width: 320px;min-width: 550px;display: table-cell;vertical-align: top;">
		<div style="width: 100% !important;">
		<!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
		<table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
		<tbody>
			<tr>
			<td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:10px 0px 0;font-family:arial,helvetica,sans-serif;" align="left">
				
		<table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 10px solid #025F3A;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
			<tbody>
			<tr style="vertical-align: top">
				<td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
				<span>&#160;</span>
				</td>
			</tr>
			</tbody>
		</table>

			</td>
			</tr>
		</tbody>
		</table>
		<table id="u_content_image_1" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
		<tbody>
			<tr>
			<td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:30px 0 33px;font-family:arial,helvetica,sans-serif;" align="left">
				
		<table width="100%" cellpadding="0" cellspacing="0" border="0">
		<tr>
			<td class="v-text-align" style="padding-right: 0px;padding-left: 0px;" align="center">
			<a href="https://unlayer.com" target="_blank">
			<img align="center" border="0" src="//via.placeholder.com/1300x300" alt="Logo" title="Logo" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;" width="143.1" class="v-src-width v-src-max-width"/>
			</a>
			</td>
		</tr>
		</table>

			</td>
			</tr>
		</tbody>
		</table>

		<!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
		</div>
		</div>
		<!--[if (mso)|(IE)]></td><![endif]-->
			<!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
			</div>
		</div>
		</div>



		<div class="u-row-container" style="padding: 0px;background-color: transparent">
		<div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 550px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
			<div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
			</div>
		</div>
		</div>



		<div class="u-row-container" style="padding: 0px;background-color: transparent">
		<div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 550px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;">
			<div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
			<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:550px;"><tr style="background-color: #ffffff;"><![endif]-->
			
		<!--[if (mso)|(IE)]><td align="center" width="542" style="width: 542px;padding: 0px;border-top: 4px solid #d9d8d8;border-left: 4px solid #d9d8d8;border-right: 4px solid #d9d8d8;border-bottom: 4px solid #d9d8d8;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
		<div class="u-col u-col-100" style="max-width: 320px;min-width: 550px;display: table-cell;vertical-align: top;">
		<div style="width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
		<!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;"><!--<![endif]-->
		

		<table id="u_content_text_1" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
		<tbody>
			<tr>
			<td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;font-family:arial,helvetica,sans-serif;" align="left">
				
		<div class="v-text-align" style="color: #333333; line-height: 140%; text-align: left; word-wrap: break-word;">
			<p style="font-size: 14px; line-height: 140%;"><span style="font-size: 18px; line-height: 25.2px; font-family: 'Crimson Text', serif;">Hi [username]</span></p>
		<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>
		<p style="font-size: 14px; line-height: 140%;"><span style="font-size: 18px; line-height: 25.2px; font-family: 'Crimson Text', serif;">Welcome to The Freshest Flick! It’s a virtual game that you can play with your friends and family after sharing a meal together.</span></p>
		<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>
		<p style="font-size: 14px; line-height: 140%;"><span style="font-size: 18px; line-height: 25.2px; font-family: 'Crimson Text', serif;">t’s not a game without a prize, right? Here are the freshest prizes:</span></p>
		</div>

			</td>
			</tr>
		</tbody>
		</table>

		<table id="u_content_image_1" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
		<tbody>
			<tr>
			<td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:30px 0 33px;font-family:arial,helvetica,sans-serif;" align="left">
				
		<table width="100%" cellpadding="0" cellspacing="0" border="0">
		<tr>
			<td class="v-text-align" style="padding-right: 0px;padding-left: 0px;" align="center">
			<a href="https://unlayer.com" target="_blank">
			<img align="center" border="0" src="../assets/images/email_marketing-my.png" alt="Logo" title="Logo" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;" width="143.1" class="v-src-width v-src-max-width"/>
			</a>
			</td>
		</tr>
		</table>

			</td>
			</tr>
		</tbody>
		</table>
		<p style="font-size: 14px; line-height: 140%; text-align:center;"><span style="font-size: 18px; line-height: 25.2px; font-family: 'Crimson Text', serif;">Follow us on social media</span></p>
		<!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
		</div>
		</div>
		<!--[if (mso)|(IE)]></td><![endif]-->
			<!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
			</div>
		</div>
		</div>



		<div class="u-row-container" style="padding: 0px;background-color: transparent">
		<div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 550px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
			<div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
			<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:550px;"><tr style="background-color: transparent;"><![endif]-->
			
		<!--[if (mso)|(IE)]><td align="center" width="550" style="width: 550px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
		<div class="u-col u-col-100" style="max-width: 320px;min-width: 550px;display: table-cell;vertical-align: top;">
		<div style="width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
		<!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->
		
		<table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
		<tbody>
			<tr>
			<td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:20px 10px 30px;font-family:arial,helvetica,sans-serif;" align="left">
				
		<div align="center">
		<div style="display: table; max-width:170px;">
		<!--[if (mso)|(IE)]><table width="170" cellpadding="0" cellspacing="0" border="0"><tr><td style="border-collapse:collapse;" align="center"><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse; mso-table-lspace: 0pt;mso-table-rspace: 0pt; width:170px;"><tr><![endif]-->
		
			
			<!--[if (mso)|(IE)]><td width="32" style="width:32px; padding-right: 25px;" valign="top"><![endif]-->
			<table align="left" border="0" cellspacing="0" cellpadding="0" width="32" height="32" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;margin-right: 25px">
			<tbody><tr style="vertical-align: top"><td align="left" valign="middle" style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
				<a href="https://facebook.com/" title="Facebook" target="_blank">
				<img src="../assets/images/image-2.png" alt="Facebook" title="Facebook" width="32" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 32px !important">
				</a>
			</td></tr>
			</tbody></table>
			<!--[if (mso)|(IE)]></td><![endif]-->
			
			<!--[if (mso)|(IE)]><td width="32" style="width:32px; padding-right: 25px;" valign="top"><![endif]-->
			<table align="left" border="0" cellspacing="0" cellpadding="0" width="32" height="32" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;margin-right: 25px">
			<tbody><tr style="vertical-align: top"><td align="left" valign="middle" style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
				<a href="https://twitter.com/" title="Twitter" target="_blank">
				<img src="../assets/images/image-4.png" alt="Twitter" title="Twitter" width="32" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 32px !important">
				</a>
			</td></tr>
			</tbody></table>
			<!--[if (mso)|(IE)]></td><![endif]-->
			
			<!--[if (mso)|(IE)]><td width="32" style="width:32px; padding-right: 0px;" valign="top"><![endif]-->
			<table align="left" border="0" cellspacing="0" cellpadding="0" width="32" height="32" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;margin-right: 0px">
			<tbody><tr style="vertical-align: top"><td align="left" valign="middle" style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
				<a href="https://linkedin.com/" title="LinkedIn" target="_blank">
				<img src="../assets/images/image-1.png" alt="LinkedIn" title="LinkedIn" width="32" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 32px !important">
				</a>
			</td></tr>
			</tbody></table>
			<!--[if (mso)|(IE)]></td><![endif]-->
			
			
			<!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
		</div>
		</div>

			</td>
			</tr>
		</tbody>
		</table>

		<!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
		</div>
		</div>
		<!--[if (mso)|(IE)]></td><![endif]-->
			<!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
			</div>
		</div>
		</div>



		<div class="u-row-container" style="padding: 0px 0px 11px;background-color: transparent">
		<div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 550px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
			<div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
			<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px 0px 11px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:550px;"><tr style="background-color: transparent;"><![endif]-->
			
		<!--[if (mso)|(IE)]><td align="center" width="275" style="width: 275px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
		<div class="u-col u-col-50" style="max-width: 320px;min-width: 275px;display: table-cell;vertical-align: top;">
		<div style="width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
		<!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->
		
		<table id="u_content_text_5" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
		<tbody>
			<tr>
			<td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
				
		<div class="v-text-align" style="color: #34495e; line-height: 180%; text-align: left; word-wrap: break-word;">
			<p style="font-size: 14px; line-height: 180%;"><span style="font-family: 'Crimson Text', serif; font-size: 18px; line-height: 32.4px;">*For the The Freshest Flick top 20 weekly Leaderboard winners.</span><br /><span style="font-family: 'Crimson Text', serif; font-size: 18px; line-height: 32.4px;">**For other players.</span><br /></p>
		</div>

			</td>
			</tr>
		</tbody>
		</table>

		<!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
		</div>
		</div>
		<!--[if (mso)|(IE)]></td><![endif]-->
		<!--[if (mso)|(IE)]><td align="center" width="275" style="width: 275px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
		<div class="u-col u-col-50" style="max-width: 320px;min-width: 275px;display: table-cell;vertical-align: top;">
		<div style="width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
		<!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->
		

		<!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
		</div>
		</div>
		<!--[if (mso)|(IE)]></td><![endif]-->
			<!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
			</div>
		</div>
		</div>


			<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
			</td>
		</tr>
		</tbody>
		</table>
	</div>
</template>

<script>
export default {
	name: "EmailTemplate",
}
</script>

<style scoped>
	table, td { color: #000000; } a { color: #0000ee; text-decoration: underline; } @media (max-width: 480px) { #u_content_image_1 .v-src-width { width: auto !important; } #u_content_image_1 .v-src-max-width #u_content_image_2 .v-src-width { width: auto !important; } #u_content_image_2 .v-src-max-width { max-width: 60% !important; } #u_content_text_1 #u_content_button_1 .v-container-padding-padding { padding: 10px 20px !important; } #u_content_button_1 .v-size-width { width: 100% !important; } #u_content_button_1 .v-text-align { text-align: left !important; } #u_content_button_1 .v-padding { padding: 15px 40px !important; } #u_content_text_3 .v-container-padding-padding { padding: 30px 30px 80px 20px !important; } #u_content_text_5 .v-text-align { text-align: left !important; } #u_content_text_4 .v-text-align { text-align: center !important; } }
	@media only screen and (min-width: 570px) {
		.u-row {
			width: 550px !important;
			margin: 0 auto;
		}
		.u-row .u-col {
			vertical-align: top;
		}

		.u-row .u-col-50 {
			width: 275px !important;
		}

		.u-row .u-col-100 {
			width: 550px !important;
		}

	}

	@media (max-width: 570px) {
	.u-row-container {
		max-width: 100% !important;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.u-row .u-col {
		min-width: 320px !important;
		max-width: 100% !important;
		display: block !important;
	}
	.u-row {
		width: calc(100% - 40px) !important;
		margin: 0 auto;
	}
	.u-col {
		width: 100% !important;
	}
	.u-col > div {
		margin: 0 auto;
	}
	}

	table,
	tr,
	td {
	vertical-align: top;
	border-collapse: collapse;
	}

	p {
	margin: 0;
	}

	.ie-container table,
	.mso-container table {
	table-layout: fixed;
	}

	a[x-apple-data-detectors='true'] {
		color: inherit !important;
		text-decoration: none !important;
	}
</style>