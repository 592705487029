import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import helper from '../helper'
import moment from 'moment'
import firebaseRepository from '../firebase/firebaseRepository'
import { auth, database } from '../firebase/firebaseInit'
import { child, get, ref, onValue, update } from "firebase/database"

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        // GAME
        gameTimer: null,
        is_game_timer_enabled: false,

        // LEADERBOARD
        leaderboardWeeklyScore: '',
        leaderboardMonthlyScore: '',

        // PROFILE
        id: null,
        email: null,
        lastRankingMonthly: null,
        lastRankingWeekly: null,
        life: null,
        myPoint: null,
        name: null,
        phone: null,
        photoPreview: null,
        userPoint: null,
        has_uploaded_receipt: false,
        is_room_master: '',
        pop_up_homepage: '',

        // RECEIPT
        selectedFile: null,
        uploadButton: false,
        loading: false,

        // ROOM
        modalExitMessage1: 'SURE YOU WANT',
        modalExitMessage2: 'TO LEAVE',
        preparationTimer: null,
        roomID: null,
        need_confirmation: true,
        is_preparation_modal_visible: false,
        is_preparation_timer_enabled: false,
        is_backsound_active: false,
        level_complete_sound: false,
        game_over_sound: false,
        audio_playing: false,
        audio_object: false,

    },
    mutations: {
        // GAME
        SET_GAME_TIMER (state, value) {
            state.gameTimer = value
        },

        START_GAME_TIMER (state, status) {
            state.is_game_timer_enabled = status
        },

        // HOMEPAGE
        SET_MODAL_VISIBILITY (state, status) {
            state.pop_up_homepage = status
        },


        // PROFILE
        GET_USER_PROFILE (state, payload) {
            state.id = payload.data().id
            state.email = payload.data().email
            state.lastRankingMonthly = payload.data().last_ranking_monthly
            state.lastRankingWeekly = payload.data().last_ranking_weekly
            state.life = payload.data().life
            state.myPoint = payload.data().point
            state.name = payload.data().name
            state.phone = payload.data().phone
            state.photoPreview = payload.data().photo_profile_url
            state.has_uploaded_receipt = payload.data().has_uploaded_receipt
            state.is_room_master = payload.data().is_room_master
            state.pop_up_homepage = payload.data().pop_up_homepage
        },


        // RECEIPT
        RESET_PHOTO_PREVIEW(state) {
            state.photoPreview = null
        },

        SET_LOADING (state, value) {
            state.loading = value
        },

        SET_STATE_UPLOAD_BUTTON(state, status) {
            state.uploadButton = status
        },


        // ROOM
        SET_CONDITION_NEED_CONFIRMATION (state, status) {
            state.need_confirmation = status
        },
        
        SET_MODAL_EXIT_MESSAGE (state, payload) {
            state.modalExitMessage1 = payload[0]
            state.modalExitMessage2 = payload[1]
        },

        SET_PREPARATION_MODAL_VISIBILITY (state, status) {
            state.is_preparation_modal_visible = status
        },

        SET_PREPARATION_TIMER (state, value) {
            state.preparationTimer = value
        },

        SET_ROOM_ID (state, roomID) {
            state.roomID = roomID
        },

        START_PREPARATION_TIMER (state, status) {
            state.is_preparation_timer_enabled = status
        },

        SET_GAME_BACKSOUND (state, value) {
            state.is_backsound_active = value
        },
        SET_LEVEL_COMPLETE_SOUND (state, value) {
            state.level_complete_sound = value
        },
        SET_GAME_OVER_SOUND (state, value) {
            state.game_over_sound = value
        },
        PLAY_AUDIO (state, value) {
            state.audio_playing = value
        },
        SET_AUDIO_OBJECT (state, value) {
            state.audio_object = value
        },
    },
    actions: {
        // AUTHENTIFICATION
        async signInWith ({ dispatch, state }, option) {
            await firebaseRepository.signIn(option).then(async () => {
                // cek user sudah terdaftar atau belum
                const user = await firebaseRepository.getDocumentOf('users', auth.currentUser.uid)
                if (!user.exists()) {
                    const dataNewUser = {
                        id: auth.currentUser.uid,
                        email: auth.currentUser.email,
                        login_type: auth.currentUser.providerData[0].providerId,
                        name: auth.currentUser.displayName,
                        last_ranking_monthly: null,
                        last_ranking_weekly: null,
                        phone: null,
                        photo_profile_url: null,
                        life: 3,
                        point: 0,
                        has_uploaded_receipt: false,
                        is_room_master: false,
                        pop_up_homepage: true,
                        last_login: new Date(auth.currentUser.metadata.lastSignInTime).toISOString(),
                        created_at: new Date(auth.currentUser.metadata.creationTime).toISOString()
                    }
                    // create user
                    const newUser = await firebaseRepository.setDocument(`users/${auth.currentUser.uid}`, dataNewUser).then(() => console.log('created')).catch((error) => console.error(error.code))
                }

                const path = router.history.current.query.redirect
                if (path !== undefined && path.split('/')[1] === 'room') {
                    const roomID = path.split('/')[2]
                    await dispatch('getUserProfile', auth.currentUser.uid)
                    get(child(ref(database), `rooms/${roomID}`)).then( async (snapshot) => {
                        if (!snapshot.exists()) {
                            alert("Room master has left the toom")
                            router.push({ name: 'Homepage' })
                            return 
                        }
                    }).catch(() => {
                        alert('Sorry.. Something went wrong!')
                        router.push({ name: 'Homepage' })
                        return 
                    })
                }

                const updatePopUpHomepage = await firebaseRepository.updateDocument(`users/${auth.currentUser.uid}`, { pop_up_homepage: true })

                router.push(router.history.current.query.redirect || { name: 'Homepage' })
            }).catch((error) => {
                if (error.code === 'auth/account-exists-with-different-credential') {
                    return alert(`User already exists. Please login using ${option === 'Google' ? 'Facebook' : 'Google'}'}`)
                }
            })
        },

        async logOut () {
            await firebaseRepository.logOut().then(() => {
                router.push({ name: 'Login' })
            }).catch((error) => console.error(error.message))
        },

        //  CREATE ROOM
        async createRoom ({ state }, roomID) {
            state.roomID = roomID
            try {
                const dataRoom = {
                    room_id: roomID,
                    room_master_id: auth.currentUser.uid,
                    participant_data: [{
                        id: auth.currentUser.uid,
                        has_created_point_history: false,
                        is_ready: false,
                        is_room_master: true,
                        name: state.name,
                        photo_profile: state.photoPreview,
                    }],
                    has_started: false,
                    is_finished: false,
                    is_game_timer_start: false,
                    is_next_level: false,
                    is_preparing_to_start: false,
                    is_timeout: false,
                    game_timer_start_at: null,
                    point_level_1_start_at: null,
                    point_level_1_finish_at: null,
                    point_level_2_start_at: null,
                    point_level_2_finish_at: null,
                    point_level_3_start_at: null,
                    point_level_3_finish_at: null,
                    preparation_timer_start_at: null,
                    level: 0,
                    point_level_1: 0,
                    point_level_2: 0,
                    point_level_3: 0,
                    total_point: 0,
                    total_candy: 0,
                    seconds_of_game_timer: 0,
                    seconds_of_preparation_timer: 0
                }

                const updateUser = await firebaseRepository.updateDocument(`users/${auth.currentUser.uid}`, { is_room_master: true })
                const createRoom = firebaseRepository.createRealtimeDB(`rooms/${state.roomID}`, dataRoom)
            } catch (error) {
                console.error(error.message)
            }
        },

        // GAME
        async cloningRooms({ state }, roomID) {
            // get current room's data
            const payload = await firebaseRepository.getDataRealtimeDB(`rooms/${roomID}`)

            if (!payload.is_finished) payload['reason'] = 'room master left'
            
            payload['clone_at'] = moment().format("YYYY-MM-DD HH:mm:ss")

            // clone current room
            firebaseRepository.addDocument('room_clones', payload)
        },

        async createPointHistory({ dispatch, state }, data) {
            await dispatch('getUserProfile', auth.currentUser.uid)

            const newPoint = state.myPoint + data.gamePoint
            const payload = {
                id: auth.currentUser.uid,
                additional_point: data.gamePoint,
                created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                new_point: newPoint,
                old_point: state.myPoint,
                seconds: data.seconds
            }

            if (!data.hasCreatedPointHistory) {
                console.log(payload)

                // update user point
                console.log('create user point')
                await firebaseRepository.updateDocument(`users/${auth.currentUser.uid}`, { point: newPoint })
            
                // create point history
                console.log('create point history')
                const path = `point_histories/${data.level}-${state.id}-${state.roomID}`
                await firebaseRepository.updateDocument(path, payload)
                // await firebaseRepository.addDocument("point_histories", payload)
            }
      
            dispatch('createPointHistoryStatus', { status: true, index: data.index })
        },

        createPointHistoryStatus({ state }, payload) {
            const updates = {}
                  updates[`rooms/${state.roomID}/participant_data/${payload.index}/has_created_point_history`] = payload.status
            update(ref(database), updates)
        },

        async getGameTimer ({ state }) {
            let serverTimeOffside = 0
            const getRoomData = await firebaseRepository.getDataRealtimeDB(`rooms/${state.roomID}`)
            const serverTime = ref(database, ".info/serverTimeOffside")
            onValue(serverTime, (snapshot) => { 
                serverTimeOffside = snapshot.val()
            })

            const startAt = getRoomData.game_timer_start_at
            const seconds = getRoomData.seconds_of_game_timer

            const timeLeft = (seconds * 1000) - (Date.now() - startAt - serverTimeOffside)
			// state.gameTimer = Math.floor(timeLeft/1000)
			state.gameTimer = seconds
        },

        async leaveGameTo({ commit, dispatch, state }, payload) {
            const roomData = await firebaseRepository.getDataRealtimeDB(`rooms/${state.roomID}`)
            commit('SET_CONDITION_NEED_CONFIRMATION', false)
            if (state.is_room_master) {
                await dispatch('cloningRooms', state.roomID)
                await dispatch('roomMasterIsLeaving')
            } else {
                // update exit_game_at
                const index = helper.findUserIndex(roomData.participant_data, state.id)

                const updates = {}
                updates[`rooms/${state.roomID}/participant_data/${index}/exit_the_game_at`] = moment().format("YYYY-MM-DD HH:mm:ss")
                updates[`rooms/${state.roomID}/participant_data/${index}/level`] = payload.level
                updates[`rooms/${state.roomID}/participant_data/${index}/reason`] = payload.reason

                if (index !== 0) update(ref(database), updates)
                else firebaseRepository.createRealtimeDB(`rooms/${state.roomID}`, null)
            }

            setTimeout(() => {
                router.replace({ name: payload.routeName })
                // router.go()
            }, 500)
        },

        async onThrowout({ state }) {
            const data = await firebaseRepository.getDataRealtimeDB(`rooms/${state.roomID}`)
            const updates = {}
            updates[`rooms/${state.roomID}/total_candy`] = data.total_candy + 1

            // update remaining candy
            if (state.is_game_timer_enabled && data.total_candy < 50) update(ref(database), updates)
        },

        resetTimer({ state }) {
            state.preparationTimer = null
            state.gameTimer = null
            state.is_game_timer_enabled = false
            state.is_preparation_timer_enabled = false
        },

        async startCountdownGameTimer({ commit, state }) {
            commit('START_GAME_TIMER', true)
            while (state.gameTimer > 0 && state.is_game_timer_enabled) {
                await new Promise(resolve => setTimeout(resolve, 1000))
                if (state.gameTimer) state.gameTimer--
            }
        },

        // LEADERBOARD
        async leaderboard ({ state }) {
            // weekly
            const dataWeekly = await firebaseRepository.getDocumentOf('leaderboards', 'weekly')

            if (dataWeekly.data() === undefined) {
                state.leaderboardWeeklyScore = { best_of_three: null, best_of_twenty: null, my_weekly_ranking: null, my_weekly_point: null }
                state.leaderboardMonthlyScore = { best_of_three: null, best_of_twenty: null, my_monthly_ranking: null, my_monthly_point: null }
                return
            }

            state.leaderboardWeeklyScore = helper.compileLeaderboard(dataWeekly, auth.currentUser.uid)

            // monthly
            const dataMonthly = await firebaseRepository.getDocumentOf('leaderboards', 'monthly')
            state.leaderboardMonthlyScore = helper.compileLeaderboard(dataMonthly, auth.currentUser.uid)
        },

        // PLAY SOLO
        async playSolo ({ dispatch, state }, roomID) {
            state.is_room_master = true
            await dispatch('createRoom', roomID)
            await dispatch('startGame')
        },

        // PROFILE
        async getUserProfile ({ commit }, id) {
            const profile = await firebaseRepository.getDocumentOf('users', id)
            commit('GET_USER_PROFILE', profile)
        },

        async updateUserProfile({dispatch, state}, payload) {
            if (state.selectedFile) dispatch("uploadFile", "Avatar")

            const profile =  await firebaseRepository.updateDocument(`users/${auth.currentUser.uid}`, payload)
            .then( () => alert("Successfully updated") )
            .catch( (error) => console.error(error.message) )
        },

        // RECEIPT
        cancelPhoto ({state}) {
            state.photoPreview = null,
            state.uploadButton = false
        },
        onPreviewImage ({ state }, payload) {
            const file = payload.target.files[0]
            helper.fetchFileURL(payload, (data) => {
                state.selectedFile = file
                state.photoPreview = data.target.result
            })
        },

        onSelectedFile({commit, dispatch, state}, payload) {
            commit("SET_STATE_UPLOAD_BUTTON", true)
            dispatch("onPreviewImage", payload)
        },

        uploadFile({state}, folderName) {
            const detailFile = state.selectedFile
            const storeImage = firebaseRepository.storeImage(folderName, detailFile)
        },

        async uploadReceipt({dispatch, state}, hasUploadedReceipt) {
            if (hasUploadedReceipt) {
                alert(`You already upload recipt this week. Upload receipts can only be once a week`)
                return
            }
            
            dispatch("uploadFile", "Receipts")
            state.uploadButton = false
            state.loading = true
            // alert(`Upload success.`)
        },

        // ROOM
        checkingAvaibilityMember ({ state }, payload) {
            let arrayParticipant = []
            arrayParticipant = payload

            if (arrayParticipant.find( (data) => data.id === auth.currentUser.uid ) === undefined) {
				arrayParticipant.push({ id: auth.currentUser.uid, has_created_point_history: false, is_room_master: false, name: state.name, photo_profile: state.photoPreview })
				firebaseRepository.createRealtimeDB(`rooms/${state.roomID}/participant_data`, arrayParticipant)
			}
        },

        async getPreparationTime ({ state }) {
            let serverTimeOffside = 0
            const getRoomData = await firebaseRepository.getDataRealtimeDB(`rooms/${state.roomID}`)
            const serverTime = ref(database, ".info/serverTimeOffset")
            onValue(serverTime, (snapshot) => { 
                serverTimeOffside = snapshot.val()
            })

            const startAt = getRoomData.preparation_timer_start_at
            const seconds = getRoomData.seconds_of_preparation_timer

            const timeLeft = (seconds * 1000) - (Date.now() - startAt - serverTimeOffside)
            // console.log(Date.now(), 'date now')
            // console.log(startAt, 'start at')
            // console.log(serverTimeOffside, 'serverTimeOffside')
			// state.preparationTimer = Math.floor(timeLeft/1000)
			state.preparationTimer = 5
        },

        async imReady ({ state }, status) {
			const roomData = await firebaseRepository.getDataRealtimeDB(`rooms/${state.roomID}`)

			const index = helper.findUserIndex(roomData.participant_data, state.id)

			const updates = {}
			updates[`rooms/${state.roomID}/participant_data/${index}/is_ready`] = status

            if (index >= 0) update(ref(database), updates)

		},

        async leaveRoom ({ commit }) {
            commit('SET_CONDITION_NEED_CONFIRMATION', false)
            router.push({ name: 'Homepage' })
        },

        async openPreparationModal ({ dispatch, state }) {
            state.is_preparation_modal_visible = true
        },

        async prepareToStart ({ dispatch, state }) {
            if (!state.is_room_master) return

            const updates = {}
            updates[`rooms/${state.roomID}/is_preparing_to_start`] = true
            await update(ref(database), updates)

            dispatch('openPreparationModal')
        },

        async resetRoomMasterStatus ({ dispatch, state }, payload) {
            const participant = payload.participant_data.find(data => data.id === state.id)
            if (!participant.is_room_master) {
                const rooms = await get(child(ref(database), 'rooms'))
                rooms.forEach((res) => {
                    let roomID = res.key,
                        response = res.val()

                    if (response.room_master_id === state.id) { 
                        dispatch('cloningRooms', roomID)
                        firebaseRepository.createRealtimeDB(`rooms/${roomID}`, null)
                    }
                })

                state.is_room_master = false
                firebaseRepository.updateDocument(`users/${state.id}`, { is_room_master: false })
            }
        },

        async roomMasterIsLeaving ({ dispatch }) {
            try {
                // change room master status become false
                await firebaseRepository.updateDocument(`users/${auth.currentUser.uid}`, { is_room_master: false })
                
                await dispatch('unsubscribe')
            } catch (error) {
                console.error(error.message)
            }
        },

        async startGame ({ state }) {
            if (!state.is_room_master) return

            // update life of room master
            await firebaseRepository.updateDocument(`users/${auth.currentUser.uid}`, { life: state.life - 1 })
            
            const updates = {}
            updates[`rooms/${state.roomID}/level`] = 1
            updates[`rooms/${state.roomID}/has_started`] = true

            // update current room data
            update(ref(database), updates)
            console.log('update life')

            router.replace({ name: 'games_play', params: { id: state.roomID } })
            // this.router.go()
        },

        async startCountdownPreparationTimer ({ commit, state }) {
            commit('START_PREPARATION_TIMER', true)
            while (state.preparationTimer > 0 && state.is_preparation_timer_enabled) {
                await new Promise(resolve => setTimeout(resolve, 1000))
                if (state.preparationTimer) state.preparationTimer--
            }
        },

        async unsubscribe ({ state }) {
            if (state.is_room_master) {
                // delete room
                firebaseRepository.createRealtimeDB(`rooms/${state.roomID}`, null)
            } else {
                const roomData = await firebaseRepository.getDataRealtimeDB(`rooms/${state.roomID}`)
                const participantData = roomData.participant_data.filter(function(result) { return result.id !== auth.currentUser.uid })
                firebaseRepository.createRealtimeDB(`rooms/${state.roomID}/participant_data`, participantData)
            }
            
        },

    },
    getters: {

    }
})
