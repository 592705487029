<template>
	<div class="bg-green-1 h-screen w-full relative pt-16 px-6 text-center">
		<img class="mx-auto" src="../assets/images/icon-emote1.svg" alt="">
		<h2 class="font-black text-white mt-3 text-5xl">AWESOME!</h2>
		<p class="text-xs text-white mt-2">YOU'RE FOCUSED BECAUSE OF YOUR MEAL, RIGHT?</p>
		<!-- <img class="mx-auto" src="../assets/images/icon-emote2.svg" alt="">
		<h2 class="font-black text-white mt-3 text-5xl">NO WORRIES</h2>
		<p class="text-xs text-white mt-2">YOU STILL MAKE THE MOST OUT OF YOUR BREAK TIME</p> -->
		<!-- <div class="w-full border-2 border-yellow-1 rounded"></div> -->
		<div class="flex justify-between gap-5 mt-9">
			<div class="bg-green-5 rounded-2xl px-5 py-2 flex flex-col shadow-2xl relative">
				<div class="absolute -top-3 left-0 right-0 flex justify-center gap-1">
					<img src="../assets/images/star-white.svg" alt="">
				</div>
				<span class="text-white-3 text-sm font-black">Level 1</span>
				<span class="text-white-3 font-black" style="font-size:9px;">COMPLETED</span>
				<h3 class="text-4xl font-black text-white">60</h3>
				<p class="text-white-3" style="font-size:9px;">points</p>
			</div>
			<div class="bg-green-5 rounded-2xl px-5 py-2 flex flex-col shadow-2xl relative">
				<div class="absolute -top-3 left-0 right-0 flex justify-center gap-1">
					<img src="../assets/images/star-white.svg" alt="">
					<img src="../assets/images/star-white.svg" alt="">
				</div>
				<span class="text-white-3 text-sm font-black">Level 2</span>
				<span class="text-white-3 font-black" style="font-size:9px;">COMPLETED</span>
				<h3 class="text-4xl font-black text-white">60</h3>
				<p class="text-white-3" style="font-size:9px;">points</p>
			</div>
			<div class="bg-green-5 rounded-2xl px-5 py-2 flex flex-col shadow-2xl relative">
				<div class="absolute -top-3 left-0 right-0 flex justify-center gap-1">
					<img src="../assets/images/star-white.svg" alt="">
					<img src="../assets/images/star-white.svg" alt="">
					<img src="../assets/images/star-white.svg" alt="">
				</div>
				<span class="text-white-3 text-sm font-black">Level 3</span>
				<span class="text-white-3 font-black" style="font-size:9px;">COMPLETED</span>
				<h3 class="text-4xl font-black text-white">60</h3>
				<p class="text-white-3" style="font-size:9px;">points</p>
			</div>
			<!-- <div class="bg-red-2 rounded-2xl px-5 py-2 flex flex-col shadow-2xl">
				<span class="text-white-3 text-sm font-black">Level 3</span>
				<span class="text-white-3 font-black" style="font-size:9px;">FAILED</span>
				<h3 class="text-4xl font-black text-white">0</h3>
				<p class="text-white-3" style="font-size:9px;">points</p>
			</div> -->
		</div>
		<div class="bg-purple-2 rounded-2xl mt-3.5 p-5 flex gap-4 justify-center items-center shadow-2xl">
			<p class="text-xl text-white font-black w-16">TOTAL POINTS</p>
			<h2 class="text-7xl text-white font-black">180</h2>
		</div>
		<div class="mt-11 mx-14 flex flex-col gap-5">
			<router-link to="/" class="rounded-full bg-white py-2 px-16 text-base text-green-5 font-bold flex justify-end items-center gap-8"><img src="../assets/images/arrow-green.svg" alt=""> Home</router-link>
			<router-link to="/leaderboard" class="rounded-full bg-gradient-to-r from-green-400 to-green-600 py-2 px-8 text-base text-white font-bold">See Leaderboard</router-link>
		</div>
	</div>
</template>

<script>
export default {
	
}
</script>
