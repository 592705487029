<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div class="bg-green-8 h-screen w-full pt-3 pb-12 relative">
        <div
          class="absolute left-0 right-0 top-1/10 w-full mx-auto text-center"
        >
          <img class="mx-auto" src="../assets/images/icon-emote3.svg" alt="" />
          <div class="mt-5">
            <h2 class="text-6xl text-white font-black">{{ modalMessage }}</h2>
          </div>
          <div class="mt-11 mx-24 flex flex-col gap-5">
            <div
              class="rounded-2xl bg-green-5 py-5 px-8 text-base text-white mx-auto"
              style="max-width: 194px; width: 100%;"
              @click="close"
            >
              Cancel
            </div>
            <button
              @click="yesDialog()"
              class="rounded-2xl bg-white-4 py-5 pl-8 pr-20 mx-auto"
              style="max-width: 194px; width: 100%;"
            >
              <div class="flex justify-center items-center gap-7">
                <img src="../assets/images/arrow-transparent.svg" alt="">
                <div class="text-base text-green-9">Yes</div>
              </div>
            </button>
            <!-- <router-link
              to="/homepage"
              class="rounded-full py-2 px-7 bg-white mx-auto"
              style="max-width: 185px; width: 100%;"
            >
              <div class="flex justify-start items-center gap-8">
                <img src="../assets/images/arrow-green.svg" alt="" />
                <div class="text-base text-green-5 font-bold">Home</div>
              </div>
            </router-link> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Howl } from 'howler';
export default {
  name: "ModalLeave",
  props: [
    'modalMessage',
  ],
  methods: {
    yesDialog() {
      this.$parent.$parent.sfx.leave.play()
      this.$emit("yesDialog");
    },
    close() {
      this.$parent.$parent.sfx.touch.play()
      this.$emit("close");
    },
  },
};
</script>
