<template>
  <div>
    <LoadingScreen :isLoading="isLoading" />
    <div class="login" v-if="!isLoading">
      <div class="fixed left-0 right-0 top-2/6">
        <img class="mx-auto" src="../assets/images/get-ready-coffee.png" alt="">
      </div>
      <div class="absolute bottom-0 bg-linear-5 rounded-t-2xl p-5 w-full text-center">
        <p class="mb-5 text-sm font-bold text-green-1">SIGN IN TO FLICK</p>
          <div class="flex flex-col gap-5">
            <button @click="signIn('Google')" class="bg-linear-7 py-4 block w-full rounded-xl flex items-center justify-center gap-6 text-base text-white font-bold"><img src="../assets/images/google.svg" alt="">SIGN IN WITH  GOOGLE</button>
            <button @click="signIn('Facebook')" class="bg-gradient-to-r from-blue-400 to-blue-700 py-4 block w-full rounded-xl flex items-center justify-center gap-7 text-base text-white font-bold"><img src="../assets/images/facebook.svg" alt="">SIGN IN WITH  FACEBOOK</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Button from '@/components/Button.vue'
import LoadingScreen from '@/components/LoadingScreen'
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  components: {
    // Button
    LoadingScreen
  },
  data() {
    return { 
      isLoading: true 
    }
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
  methods: {
    ...mapActions([
      'signInWith'
    ]),
    signIn (option) {
      this.signInWith(option)
    }
  }
}
</script>
