<template>
	<transition name="modal-fade">
		<div class="modal-backdrop">
			<div class="modal">
				<div class="bg-linear-4 w-full px-5 pt-20 pb-12">
					<div class="absolute top-3 right-4 flex gap-1 items-center" @click="close">
						<span class="text-base text-white">close</span>
						<a href="#"><img src="../assets/images/button-close.svg" alt=""></a>
					</div>
					<div class="text-center relative" style="margin-bottom: 32px;">
						<h2 class="text-3xl font-black text-white">FLICK & WIN</h2>
						<h2 class="text-3xl font-black text-white">GRAND PRIZES</h2>
					</div>
					<div class="text-center">
						<img class="mx-auto" src="../assets/images/airpod-max.png" alt="" style="width: 90%;">
						<h2 class="text-2xl font-bold text-white">AIRPOD MAX</h2>
						<p class="text-sm text-white font-medium">5x Grand Prize Winners with the highest scores.</p>
						<p class="text-sm text-white font-medium">Winners list will be announced on 8th April 2022.</p>
					</div>
					<div class="text-center relative mt-6">
					<h2 class="text-2xl font-bold text-white">Weekly Prizes</h2>
					<div class="mt-5 text-center">
						<div class="relative w-52 mx-auto">
							<img class="mx-auto" src="../assets/images/lazada-voucher.svg" alt="">
							<div class="absolute -bottom-4 -left-2">
								<img src="../assets/images/40-item.png" class="w-14 h-12" alt="">
								<!-- <span class="absolute top-1 left-3 text-green-3 font-bold text-2xl" style="-webkit-text-stroke: 1px white;">20</span> -->
							</div>
						</div>
						<h3 class="text-lg text-white font-bold">Lazada Vouchers</h3>
						<p class="text-sm text-white font-medium">20x Weekly Leaderboard Winners with highest scores of the week.</p>
						<p class="text-sm text-white font-medium">Winners list will be announced every week.</p>
					</div>
					<div class="mt-5 text-center">
						<div class="relative w-52 h-32 mx-auto mb-10">
							<img class="object-contain mx-auto" src="../assets/images/grab-voucher.png" alt="">
							<div class="absolute -bottom-7 -left-4">
								<img src="../assets/images/1000-item.png">
							</div>
						</div>
						<h3 class="text-lg text-white font-bold">Grab Vouchers</h3>
						<p class="text-sm text-white font-medium">1000x Consolation Prize for Top 1.000 scores.</p>
						<p class="text-sm text-white font-medium">Winners will be announced on the 8th April 2022.</p>
						<p class="text-sm text-white font-medium">Exclude Grand Prize Winners.</p>
					</div>
					<div class="mt-14 w-76 mx-auto border-2 border-yellow-1 rounded-xl p-4">
						<h3 class="text-2xl font-bold text-yellow-1 text-center">Here’s how</h3>
						<div class="flex items-center text-left gap-4 mt-5">
							<img class="w-12 h-12" src="../assets/images/flick-hand.svg" alt="">
							<p class="text-sm text-yellow-1">Flick gums out of the pack</p>
						</div>
						<div class="flex items-center text-left gap-4 mt-5">
							<img class="w-12 h-12" src="../assets/images/leaf-life.svg" alt="">
							<p class="text-sm text-yellow-1">You'll have 3 lives for each day</p>
						</div>
						<div class="flex items-center text-left gap-4 mt-5">
							<img class="w-12 h-12" src="../assets/images/friend.svg" alt="">
							<p class="text-sm text-yellow-1">Play solo or get your friends</p>
						</div>
						<div class="flex items-center text-left gap-4 mt-5">
							<img class="w-12 h-12" src="../assets/images/share.svg" alt="">
							<p class="text-sm text-yellow-1"><b>Easier</b> to complete the levels when you play together</p>
						</div>
						<div class="flex items-center text-left gap-4 mt-5">
							<img class="w-12 h-12" src="../assets/images/camera-yellow.svg" alt="">
							<p class="text-sm text-yellow-1">Upload receipt & bump up your points</p>
						</div>
					</div>
					
					<router-link to="/terms-and-condition">
						<span class="mt-3.5 text-sm font-bold text-yellow-1 text-center block underline" @click="close">*Terms & conditions apply</span>
					</router-link>

					</div>
					
					
				</div>
			</div>

		</div>
	</transition>
</template>

<script>
  export default {
    name: 'ModalPages',
    methods: {
      close () {
		  this.$parent.$parent.sfx.leave.play()
          this.$emit('close')
      },
    }
  }
</script>
