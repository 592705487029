<template>
  <div class="modal-backdrop">
    <div class="modal">
        <!-- TODO: 
            - Copytext awesome & no worries
         -->
      <div class="bg-green-8 h-screen w-full relative pt-16 px-6 text-center">
        <div v-if="pointLevel1 > 0 && pointLevel2 > 0 && pointLevel3 > 0">
          <img class="mx-auto" src="../assets/images/icon-emote1.svg" alt="" />
          <h2 class="font-black text-white mt-3 text-5xl">AWESOME!</h2>
          <p class="text-xs text-white mt-2">
            KEEP WINNING WITH YOUR COFFEE INTAKE
          </p>
        </div>
        <div v-else>
          <img class="mx-auto" src="../assets/images/icon-emote2.svg" alt="" />
          <h2 class="font-black text-white mt-3 text-5xl">NO WORRIES</h2>
          <p class="text-xs text-white mt-2">
            YOU STILL MAKE THE MOST OUT OF YOUR BREAK TIME
          </p>
        </div>
        <!-- START LEVEL 2 -->
        <div class="flex justify-between gap-5 mt-9 relative">
          <div class="slash-4"></div>
          <div
            class="bg-green-5 rounded-2xl px-5 py-2 flex flex-col shadow-2xl relative min-w-88"
            v-if="pointLevel1 > 0"
          >
            <div
              class="absolute -top-3 left-0 right-0 flex justify-center gap-1"
            >
              <img src="../assets/images/star-white.svg" alt="" />
            </div>
            <span class="text-white-3 text-sm font-black">Level 1</span>
            <span class="text-white-3 font-black" style="font-size: 9px"
              >COMPLETED</span
            >
            <h3 class="text-4xl font-black text-white">{{ pointLevel1 }}</h3>
            <p class="text-white-3" style="font-size: 9px">points</p>
          </div>
          <div
            class="bg-red-2 rounded-2xl px-5 py-2 flex flex-col shadow-2xl relative min-w-88"
            v-else
          >
            <span class="text-white-3 text-sm font-black">Level 1</span>
            <span class="text-white-3 font-black" style="font-size: 9px"
              >FAILED</span
            >
            <h3 class="text-4xl font-black text-white">{{ pointLevel1 }}</h3>
            <p class="text-white-3" style="font-size: 9px">points</p>
          </div>

          <!-- START LEVEL 2 -->
          <div
            class="bg-green-5 rounded-2xl px-5 py-2 flex flex-col shadow-2xl relative min-w-88"
            v-if="pointLevel2 > 0"
          >
            <div
              class="absolute -top-3 left-0 right-0 flex justify-center gap-1"
            >
              <img src="../assets/images/star-white.svg" alt="" />
              <img src="../assets/images/star-white.svg" alt="" />
            </div>
            <span class="text-white-3 text-sm font-black">Level 2</span>
            <span class="text-white-3 font-black" style="font-size: 9px"
              >COMPLETED</span
            >
            <h3 class="text-4xl font-black text-white">{{ pointLevel2 }}</h3>
            <p class="text-white-3" style="font-size: 9px">points</p>
          </div>
          <div
            class="bg-red-2 rounded-2xl px-5 py-2 flex flex-col shadow-2xl relative min-w-88"
            v-else
          >
            <span class="text-white-3 text-sm font-black">Level 2</span>
            <span class="text-white-3 font-black" style="font-size: 9px"
              >FAILED</span
            >
            <h3 class="text-4xl font-black text-white">{{ pointLevel2 }}</h3>
            <p class="text-white-3" style="font-size: 9px">points</p>
          </div>
          <!-- END LEVEL 2 -->

          <!-- START LEVEL 3 -->
          <div
            class="bg-green-5 rounded-2xl px-5 py-2 flex flex-col shadow-2xl relative min-w-88"
            v-if="pointLevel3 > 0"
          >
            <div
              class="absolute -top-3 left-0 right-0 flex justify-center gap-1"
            >
              <img src="../assets/images/star-white.svg" alt="" />
              <img src="../assets/images/star-white.svg" alt="" />
              <img src="../assets/images/star-white.svg" alt="" />
            </div>
            <span class="text-white-3 text-sm font-black">Level 3</span>
            <span class="text-white-3 font-black" style="font-size: 9px"
              >COMPLETED</span
            >
            <h3 class="text-4xl font-black text-white">{{ pointLevel3 }}</h3>
            <p class="text-white-3" style="font-size: 9px">points</p>
          </div>
          <div
            class="bg-red-2 rounded-2xl px-5 py-2 flex flex-col shadow-2xl relative min-w-88"
            v-else
          >
            <span class="text-white-3 text-sm font-black">Level 3</span>
            <span class="text-white-3 font-black" style="font-size: 9px"
              >FAILED</span
            >
            <h3 class="text-4xl font-black text-white">{{ pointLevel3 }}</h3>
            <p class="text-white-3" style="font-size: 9px">points</p>
          </div>
          <!-- END LEVEL 3 -->
        </div>
        <div
          class="bg-purple-2 rounded-2xl mt-3.5 p-5 flex gap-4 justify-center items-center shadow-2xl"
        >
          <p class="text-xl text-white font-black w-16">TOTAL POINTS</p>
          <h2 class="text-7xl text-white font-black">{{ pointLevel1 + pointLevel2 + pointLevel3 }}</h2>
        </div>
        <div class="mt-11 mx-14 flex flex-col gap-5">
          <button
            @click="$emit('leaveGameTo', 'Homepage')"
            class="rounded-full py-2 px-7 bg-white mx-auto"
            style="max-width: 194px; width: 100%;"
          >
            <div class="flex justify-start items-center gap-8">
              <img src="../assets/images/arrow-green.svg" alt="" />
              <div class="text-base text-green-5 font-bold">Home</div>
            </div>
          </button>
          <button
            @click="$emit('leaveGameTo', 'Leaderboard')"
            class="rounded-full bg-gradient-to-r from-green-400 to-green-600 py-2 px-8 text-base text-white font-bold mx-auto"
            style="max-width: 194px; width:100%;"
            >See Leaderboard</button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Howl, Howler } from 'howler';
export default {
  name: "ModalSummary",
  props: [
    'pointLevel1',
    'pointLevel2',
    'pointLevel3',
  ],
  mounted() {
    this.$store.commit('PLAY_AUDIO', false)
    // this.$parent.$parent.sfx.gameWin1.play()
    // this.$parent.$parent.sfx.gameWin2.play()
    
    console.log(this)
    // Howler.unload();
    this.$store.commit('SET_GAME_BACKSOUND', false)
  }
  // methods: {
  //   close() {
  //     this.$emit("close");
  //   },
  // },
};
</script>
